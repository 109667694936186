import { BLOCKS_CORE_ADDRESS, PANELS_CORE_ADDRESS } from 'enums/dotenv'

const CONTRACTS_ID = {
  blocks: BLOCKS_CORE_ADDRESS,
  panels: PANELS_CORE_ADDRESS,
}

export const OPENSEA_URL = `https://opensea.io/assets`

export const getOpenSeaUrl = (id, contract) =>
  `${OPENSEA_URL}/${CONTRACTS_ID[contract]}/${id}`

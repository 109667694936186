import { useContext, useEffect, useMemo, useState } from 'react'
import { ethers } from 'ethers'
import { Box, Text } from '@chakra-ui/layout'
import { useToast } from '@chakra-ui/toast'
import Button from 'components/atoms/Button'
import EthersContext from 'context/EthersContext'
import LoadingTransaction from 'components/atoms/LoadingTransaction'
import LoadingApproval from 'components/atoms/LoadingApproval'
import LoadingSuccessful from 'components/atoms/LoadingSuccessful'
import LoadingError from 'components/atoms/LoadingError'
import Panel from 'components/atoms/Panel'
import PanelForm from 'components/molecules/PanelForm'
import PanelContext from 'context/PanelContext'
// import { GAS_LIMIT } from 'enums/dotenv'
import Toast from 'components/atoms/Toast'
import { PanelLeft, SidePanel } from 'components/templates/PanelContainerModal'

function FillPanelModal({
  size,
  closePanel,
  id = null,
  name = '',
  description = '',
}) {
  const [columns, rows] = useMemo(() => size.split('x'), [size])
  const totalBlocks = useMemo(() => columns * rows, [columns, rows])
  const { refreshData, tilesMinterContract } = useContext(EthersContext)
  const { isLoadingBlocks, refetchBlocks, refetchPanels } =
    useContext(PanelContext)
  const [mintStatus, setMintStatus] = useState('')
  const [formValue, setFormValue] = useState({ name, description })
  const [lastTransactionHash, setLastTransactionHash] = useState('')
  const [currentPanel, setCurrentPanel] = useState(
    Array(totalBlocks).fill(null)
  )
  const [errorMessage, setErrorMessage] = useState(null)
  const [timeoutInstance, setTimeoutInstance] = useState(1)
  const toast = useToast()

  useEffect(() => {
    setCurrentPanel(Array(totalBlocks).fill(null))
    setFormValue({ name, description })
  }, [description, name, totalBlocks])

  const onChangeFormValue = (field, value) => {
    setFormValue({ ...formValue, [field]: value })
  }

  const resetMintStatus = () => {
    const timeout = setTimeout(() => {
      if (setMintStatus) {
        setMintStatus('')
        setErrorMessage(null)
      }
    }, 20000)
    setTimeoutInstance(timeout)
    refreshData()
  }

  const mintPanel = async () => {
    setMintStatus('loading')
    clearTimeout(timeoutInstance)

    try {
      let mintOrFillMethod = 'mintPanel'
      const params = [
        formValue?.name.trim() ?? '',
        formValue?.description.trim() ?? '',
      ]
      if (id) {
        params.unshift(id)
        mintOrFillMethod = 'fillEmptyToken'
      } else {
        params.push(totalBlocks)
      }
      params.push(currentPanel.map((block) => block.id))
      params.push({
        value: ethers.utils.parseEther('0'),
        // gasLimit: GAS_LIMIT,
      })

      const mint = await tilesMinterContract[mintOrFillMethod](...params)

      setMintStatus('loading transaction')
      console.log(mint)
      setLastTransactionHash(mint.hash)
      const mintWaited = await mint.wait()
      console.log('mint success', mintWaited)
      refetchBlocks()
      refetchPanels()
      toast({
        position: 'top-right',
        render: ({ onClose }) => (
          <Toast onClose={onClose}>
            <Text>Panel minted sucessfully!</Text>
            <Text>Check your panel at your wallet</Text>
          </Toast>
        ),
        duration: 30000,
        isClosable: true,
      })
      closePanel()
    } catch (error) {
      console.log(error)
      setMintStatus('error')
      setErrorMessage(error.message)
      resetMintStatus()
    }
  }
  const isLoading = mintStatus.includes('loading')
  const isAbleToMint = !(isLoading || currentPanel.includes(null))

  return (
    <>
      <PanelLeft>
        {isLoadingBlocks && <div>Loading blocks...</div>}
        {!isLoadingBlocks && (
          <Panel
            totalBlocks={totalBlocks}
            columns={columns}
            rows={rows}
            onChangePanel={setCurrentPanel}
            panel={currentPanel}
            isDisabled={isLoading}
          />
        )}
      </PanelLeft>
      <SidePanel>
        {!isLoadingBlocks && (
          <>
            <PanelForm
              onChangeValue={onChangeFormValue}
              isDisabled={isLoading}
              formValues={formValue}
            />
            {mintStatus && (
              <Box textAlign="center" py="5">
                {mintStatus === 'loading' && <LoadingApproval />}
                {mintStatus === 'loading transaction' && (
                  <LoadingTransaction transactionHash={lastTransactionHash} />
                )}
                {mintStatus === 'success' && <LoadingSuccessful />}
                {mintStatus === 'error' && (
                  <LoadingError>{errorMessage}</LoadingError>
                )}
              </Box>
            )}
            <Box textAlign="center" px="0" mt="7">
              <Button
                disabled={!isAbleToMint}
                onClick={mintPanel}
                className="w-full"
              >
                Mint Panel
              </Button>
              <Text
                fontSize="sm"
                fontWeight="500"
                mt="1"
                textColor="blackAlpha.800"
              >
                the blocks used will be burned
              </Text>
            </Box>
          </>
        )}
      </SidePanel>
    </>
  )
}

export default FillPanelModal

import { extendTheme } from '@chakra-ui/react'

const headlineHeight = {
  fontVariationSettings: "'opsz' 9",
}

const theme = extendTheme({
  fonts: {
    heading: 'Bodoni ModaVariable',
    body: 'Roboto',
  },
  components: {
    Text: {
      baseStyle: {
        lineHeight: '200%',
        fontWeight: '300',
      },
    },
    Heading: {
      baseStyle: {
        fontVariationSettings: "'opsz' 9",
        fontWeight: 300,
      },
      sizes: {
        xl: {
          fontSize: '3.45em',
          fontWeight: 300,
        },
      },
    },
  },
  styles: {
    global: {
      h1: headlineHeight,
      h2: headlineHeight,
      h3: headlineHeight,
      h4: headlineHeight,
    },
  },
})

export default theme

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import { Modal } from '@chakra-ui/modal'
import { Box, Link, Text } from '@chakra-ui/layout'
import { Select } from '@chakra-ui/select'
import { Textarea } from '@chakra-ui/textarea'
import { Input } from '@chakra-ui/input'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { IconButton } from '@chakra-ui/react'
import { CopyIcon } from '@chakra-ui/icons'
import { useToast } from '@chakra-ui/toast'
import EthersContext from 'context/EthersContext'
import Panel from 'components/atoms/Panel'
import Button from 'components/atoms/Button'
import PanelContext, { generateBlockObj } from 'context/PanelContext'
import { DEFAULT_PANELS } from 'enums/panels'
import Toast from 'components/atoms/Toast'
import PanelContainerModal from '../PanelContainerModal'

const parseBlocksToIds = (text) => text?.match(/([0-9\b]+)/gi) ?? []
const queryStringConfig = { arrayFormat: 'comma', parseNumbers: true }
const handleQueryString = (qs, newValue) => ({
  search: queryString.stringify(
    {
      ...qs,
      ...newValue,
    },
    queryStringConfig
  ),
})

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Async: Could not copy text: ', err)
    }
  )
}

export const Playground = () => {
  let history = useHistory()
  let location = useLocation()
  const queryParams = useMemo(
    () => queryString.parse(location.search, { parseNumbers: true }),
    [location.search]
  )
  const { getImageUrl, blocks: contextBlocks } = useContext(PanelContext)
  const { panelsInfo } = useContext(EthersContext)
  const [panelSize, setPanelSize] = useState('')
  const [blocks, setBlocks] = useState([])
  const [currentPanel, setCurrentPanel] = useState([])
  const [textAreaValue, setTextAreaValue] = useState('')
  const [columns, setColumns] = useState(null)
  const [rows, setRows] = useState(null)
  const [totalBlocks, setTotalBlocks] = useState(null)
  const toast = useToast()

  const options = useMemo(() => {
    return Object.keys(panelsInfo).reduce((acc, curr) => {
      const panel = panelsInfo[curr]
      return [...acc, { text: curr, value: panel.totalBlocks }]
    }, [])
  }, [panelsInfo])

  // Handle panel size state
  useEffect(() => {
    if (queryParams?.panelSize) {
      if (!DEFAULT_PANELS[queryParams.panelSize]) {
        history.replace({
          search: '',
        })
        return
      }
      const [c, r] = queryParams?.panelSize.split('x')
      const blocksTotal = parseInt(c, 10) * parseInt(r, 10)

      setPanelSize(queryParams?.panelSize)
      setColumns(c)
      setRows(r)
      setTotalBlocks(blocksTotal)
    } else {
      setPanelSize('')
      setColumns(null)
      setRows(null)
      setTotalBlocks(null)
    }
  }, [history, queryParams.panelSize])

  // Handle panel filled state
  useEffect(() => {
    if (queryParams?.panel) {
      const newPanel = queryParams.panel
        .split(',')
        .map((id) => (id === '' ? null : parseInt(id, 10)))

      setCurrentPanel(newPanel)
    }
  }, [queryParams?.panel])

  // Handle colum vs rows (render panel properly)
  useEffect(() => {
    if (columns && rows) {
      const totalBlocks = columns * rows
      if (currentPanel.length === totalBlocks) return
      setCurrentPanel(Array(totalBlocks).fill(null))
    }
  }, [columns, currentPanel.length, rows])

  // Handle blocks added by a user
  useEffect(() => {
    if (queryParams?.blocks) {
      const blocksId = parseBlocksToIds(queryParams.blocks.toString())

      const QueryBlocks = blocksId.map((id) => ({
        id: parseInt(id, 10),
        thumbnail: getImageUrl(id, 25),
      }))
      setBlocks(QueryBlocks)
      setTextAreaValue(blocksId.join(','))
    }
  }, [getImageUrl, queryParams?.blocks])

  const handleIds = useCallback(() => {
    if (textAreaValue.length < 1) return
    let idList = parseBlocksToIds(textAreaValue)

    if (idList.length < 1) return

    idList = Array.from(new Set(idList))

    setTextAreaValue(idList.join(','))

    history.replace(handleQueryString(queryParams, { blocks: idList }))

    toast({
      position: 'top-right',
      render: ({ onClose: onCloseToast }) => (
        <Toast onClose={onCloseToast} noClose>
          <Text>Blocks loaded! You can now use them on the Panel</Text>
        </Toast>
      ),
      duration: 3000,
      isClosable: true,
    })
  }, [history, queryParams, textAreaValue, toast])

  const loadMyBlocks = () => {
    const contextBlocksIds = contextBlocks.map((block) => block.id)
    setTextAreaValue(
      (value) =>
        `${value}${value.length > 0 ? ',' : ''}${contextBlocksIds.join(',')}`
    )
  }

  const onCloseModal = () => {
    history.push('/')
  }

  const handleChangeSize = (e) => {
    const sizeValue = e.target.value
    setPanelSize(sizeValue)

    if (sizeValue === '') {
      history.replace({
        search: '',
      })
      return
    }

    history.replace(
      handleQueryString(queryParams, { panelSize: sizeValue, panel: [] })
    )
  }
  const handleChangePanel = (list) => {
    const newPanel = list.map((block) => block?.id ?? null)
    history.replace(handleQueryString(queryParams, { panel: newPanel }))
  }
  const handleTextArea = (e) => {
    setTextAreaValue(e.target.value)
  }

  return (
    <Modal onClose={onCloseModal} isOpen size="full">
      <PanelContainerModal
        title="Panel Playground"
        shouldRenderPanel={columns && rows}
        renderSelect={
          <Select
            placeholder="Choose a size"
            onChange={handleChangeSize}
            value={panelSize}
            w="xs"
            mb="5"
            ml={{ base: 0, md: 10 }}
          >
            {options.map(({ value, text }) => (
              <option value={text} key={value}>
                {text}
              </option>
            ))}
          </Select>
        }
        renderPanel={
          <Panel
            totalBlocks={totalBlocks}
            columns={columns}
            rows={rows}
            onChangePanel={handleChangePanel}
            panel={currentPanel.map((blockId) =>
              blockId ? generateBlockObj(blockId) : null
            )}
            isDisabled={false}
            blocks={blocks}
          />
        }
        renderSidePanel={
          <>
            <FormControl>
              <FormLabel>
                Insert Blocks ids to play with (separated by comma)
              </FormLabel>
              <Textarea
                placeholder="27, 32, 33, 31"
                value={textAreaValue}
                onChange={handleTextArea}
                maxLength="1000"
              />

              {contextBlocks.length > 0 && (
                <Box textAlign="right">
                  <Link
                    onClick={loadMyBlocks}
                    textDecor="underline"
                    fontSize="sm"
                  >
                    Get my blocks
                  </Link>
                </Box>
              )}
            </FormControl>
            <Box textAlign="center" mt="5" mb="10">
              <Button
                onClick={handleIds}
                disabled={
                  textAreaValue.length < 1 ||
                  queryParams?.blocks === textAreaValue
                }
              >
                Load Blocks
              </Button>
            </Box>
            <FormControl mb="10">
              <FormLabel>Share this panel:</FormLabel>
              <Box display="flex">
                <Input
                  type="url"
                  id="foo"
                  value={window.location.href}
                  readOnly
                />
                <IconButton
                  icon={<CopyIcon />}
                  ml="2"
                  onClick={() => {
                    copyTextToClipboard(window.location.href)
                    toast({
                      position: 'top-right',
                      render: ({ onClose: onCloseToast }) => (
                        <Toast onClose={onCloseToast} noClose>
                          <Text>URL Copied!</Text>
                        </Toast>
                      ),
                      duration: 1000,
                      isClosable: false,
                    })
                  }}
                >
                  Copy url
                </IconButton>
              </Box>
            </FormControl>
          </>
        }
      />
    </Modal>
  )
}

export default Playground

import { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Box, Heading } from '@chakra-ui/layout'
import Button from 'components/atoms/Button'
import EthersContext from 'context/EthersContext'

const unpauseMint = (tilesMinterContract) => async () => {
  try {
    const unpauseResult = await tilesMinterContract.unpause()
    await unpauseResult.wait()
    console.log(unpauseResult)
    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}

const startPanelMint = (tilesMinterContract) => async () => {
  try {
    const setStartPanelMint = await tilesMinterContract.startPanelMint()
    await setStartPanelMint.wait()
    console.log(setStartPanelMint)
    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}

const pauseMint = (tilesMinterContract) => async () => {
  try {
    const pauseResult = await tilesMinterContract.pause()
    await pauseResult.wait()
    console.log(pauseResult)
    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}

const setGenesisMintLimit = (contract) => async () => {
  const value = Number(window.prompt('Set the limit of blocks to mint', 10000))
  if (!value) return
  try {
    const setResult = await contract.setGenesisMintLimit(value)
    await setResult.wait()
    console.log(setResult)
    window.location.reload()
  } catch (error) {
    console.log(error)
  }
}
const onWithdraw = (contract) => async () => {
  try {
    const setResult = await contract.withdrawEther()
    await setResult.wait()
    console.log(setResult)
    alert('Withdraw successfully')
    window.location.reload()
  } catch (error) {
    alert('Withdraw error, see the console.log')
    console.log(error)
  }
}

function Header({ mintIsPaused, panelsMintStarted }) {
  const { tilesMinterContract, blocksCoreContract, isAdmin } =
    useContext(EthersContext)

  return (
    <Box
      as="header"
      shadow="sm"
      bgColor="gray.50"
      p="4"
      top="0"
      display="flex"
      justifyContent="space-between"
      position="sticky"
      zIndex="2"
    >
      <Heading as="h1" size="lg">
        The Panels
      </Heading>
      <nav className="flex">
        <ul className="flex items-center">
          {isAdmin && (
            <>
              <li className="mr-4">
                {mintIsPaused && (
                  <Button small onClick={unpauseMint(tilesMinterContract)}>
                    unpause mint
                  </Button>
                )}
                {!mintIsPaused && (
                  <Button small onClick={pauseMint(tilesMinterContract)}>
                    pause mint
                  </Button>
                )}
              </li>
              {!panelsMintStarted && (
                <li
                  className="mr-4"
                  onClick={startPanelMint(tilesMinterContract)}
                >
                  <Button small>start panel mint</Button>
                </li>
              )}
              <li className="mr-4">
                <Button small onClick={onWithdraw(tilesMinterContract)}>
                  withdraw
                </Button>
              </li>
              <li
                className="mr-4"
                onClick={setGenesisMintLimit(blocksCoreContract)}
              >
                <Button small>Set blocks Genesis Limit</Button>
              </li>
            </>
          )}
          <li className="mr-4">
            <a
              href="https://twitter.com/EmergenceDAO"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li>
            <a
              href="https://discord.gg/emergence"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} />
            </a>
          </li>
        </ul>
      </nav>
    </Box>
  )
}

export default Header

import { useContext, useState } from 'react'
import { Modal } from '@chakra-ui/modal'
import { Select } from '@chakra-ui/select'
import PanelContext from 'context/PanelContext'
import { PANEL_BY_SIZE } from 'enums/panels'
import usePanelWidth from 'foundation/hooks/usePanelWidth'
import PanelContainerModal from 'components/templates/PanelContainerModal'
import FillPanelModal from '../FillPanelModal'

function FillEmptyPanelModal({ onClose, isOpen }) {
  const { emptyPanels } = useContext(PanelContext)
  const [panelWidth] = usePanelWidth()
  const [panelSelected, setPanelSelected] = useState(emptyPanels[0])
  const [selectValue, setSelectValue] = useState(0)

  const handleChange = (e) => {
    setSelectValue(e.target.value)
    setPanelSelected(emptyPanels[e.target.value])
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="full">
      <PanelContainerModal
        title="Fill Panel"
        shouldRenderPanel={panelSelected.size}
        renderSelect={
          <Select
            onChange={handleChange}
            value={selectValue}
            width={panelWidth}
            w="xs"
            mb="5"
            ml={{ base: 0, md: 10 }}
          >
            {emptyPanels.map(({ name, degradationLevel, id, size }, index) => (
              <option value={index} key={id}>
                {name.length > 0 ? name : `Panel #${id}`} [
                {PANEL_BY_SIZE[size].size}] [degradation: {degradationLevel}
                /4]
              </option>
            ))}
          </Select>
        }
        renderContainer={
          <FillPanelModal
            id={panelSelected.id}
            name={panelSelected.name}
            description={panelSelected.description}
            size={PANEL_BY_SIZE[panelSelected.size].size}
            closePanel={onClose}
          />
        }
      />
    </Modal>
  )
}
export default FillEmptyPanelModal

import { Box, Heading, Text } from '@chakra-ui/layout'

export const Properties = ({ values = [], ...props }) =>
  values.length < 1 ? (
    ''
  ) : (
    <Box
      as="section"
      mt="5"
      display="flex"
      flexWrap="wrap"
      textAlign="center"
      justifyContent="stretch"
      alignContent="stretch"
      mx="-3"
      {...props}
    >
      {values.map(({ trait_type, value }) => (
        <Box
          border="1px solid black"
          borderColor="blackAlpha.500"
          _hover={{ borderColor: 'black' }}
          key={trait_type}
          maxW="250px"
          m="2"
          py="2"
          px="4"
          borderRadius="3"
        >
          <Heading size="sm">{trait_type}</Heading>
          <Text fontWeight="500" fontSize="md">
            {value}
          </Text>
        </Box>
      ))}
    </Box>
  )

export default Properties

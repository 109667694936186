import { Box, Heading } from '@chakra-ui/layout'
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { Fade } from '@chakra-ui/transition'

export const SidePanel = ({ children }) => (
  <Box
    w={{ base: '100%', xl: '30%' }}
    pr={{ xl: '10%' }}
    pt={{ base: 10, xl: 0 }}
    display="flex"
    flexDir="column"
    alignContent="center"
    justifyContent="center"
  >
    {children}
  </Box>
)

export const PanelLeft = ({ children }) => (
  <Box flex="1" mr={{ base: 0, lg: 10 }}>
    {children}
  </Box>
)

export const PanelContainerModal = ({
  title,
  renderSelect,
  renderPanel,
  renderSidePanel,
  renderContainer,
  shouldRenderPanel = false,
}) => {
  return (
    <>
      <ModalOverlay />
      <ModalContent borderRadius="none">
        <ModalHeader display="flex" flexDir={{ base: 'column', md: 'row' }}>
          <Heading size="lg" mb={{ base: 5, md: 0 }}>
            {title}
          </Heading>
          {typeof renderSelect !== 'undefined' && renderSelect}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {shouldRenderPanel && (
            <Fade in>
              <Box display="flex" flexDir={{ base: 'column', xl: 'row' }}>
                {!renderContainer ? (
                  <>
                    <PanelLeft>{renderPanel}</PanelLeft>
                    <SidePanel>{renderSidePanel}</SidePanel>
                  </>
                ) : (
                  renderContainer
                )}
              </Box>
            </Fade>
          )}
        </ModalBody>
      </ModalContent>
    </>
  )
}

export default PanelContainerModal

import { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Heading, Text } from '@chakra-ui/layout'
import { Collapse } from '@chakra-ui/transition'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { Image } from '@chakra-ui/image'
import { Skeleton } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks'
import PanelContext from 'context/PanelContext'
import BlocksPreview from 'components/atoms/BlocksPreview'
import Button from 'components/atoms/Button'
import LoadingApproval from 'components/atoms/LoadingApproval'
import LoadingTransaction from 'components/atoms/LoadingTransaction'
import LoadingError from 'components/atoms/LoadingError'
import BePatientMessage from 'components/atoms/BePatientMessage'

const Block = ({ block, onClick = () => {}, selectable, isSelected }) => {
  const [image, setImage] = useState(null)
  const [hasError, setHasError] = useState(false)
  const loadImage = useCallback(() => {
    const windowImage = new window.Image()
    windowImage.src = block.thumbnail
    windowImage.onload = () => {
      setImage(block.thumbnail)
    }
    windowImage.onerror = () => {
      setHasError(true)
    }
  }, [block.thumbnail])

  useEffect(() => {
    loadImage()
  }, [loadImage])

  if (hasError) return <></>

  return (
    <Skeleton
      isLoaded={image}
      boxSize="10%"
      minW={{ base: 100, md: 200 }}
      minH={{ base: 100, md: 200 }}
      key={block.id}
      m="2"
      float="left"
      position="relative"
      role="group"
      cursor="pointer"
      onClick={onClick}
    >
      <Image boxSize="full" src={image} />
      <Box
        position="absolute"
        boxSize="full"
        top="0"
        left="0"
        zIndex="3"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="blackAlpha.800"
        textColor="white"
        opacity="0"
        _groupHover={{ opacity: 1 }}
        transition="opacity .3s ease"
      >
        {!isSelected && (
          <Text>Click to {selectable ? 'select' : 'preview'}</Text>
        )}
        {isSelected && <Text>Click to remove</Text>}
      </Box>
      {isSelected && (
        <Box
          position="absolute"
          boxSize="full"
          top="0"
          left="0"
          zIndex="2"
          display="flex"
          justifyContent="right"
          alignItems="top"
          bgColor="blackAlpha.600"
          textColor="white"
          pt="1"
          pr="3"
          opacity="1"
          _groupHover={{ opacity: 0 }}
          transition="opacity .3s ease"
        >
          <Text>Selected</Text>
        </Box>
      )}
    </Skeleton>
  )
}

function BlocksView({
  onClose,
  isOpen,
  selectable = false,
  onToggleSelect,
  selectedBlocks,
  onBurn,
  burnStatus,
  lastTransactionHash,
  errorMessage,
}) {
  const { blocks, getImageUrl } = useContext(PanelContext)
  const [previewImage, setPreviewImage] = useState(null)
  const [previewImageId, setPreviewImageId] = useState(null)
  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreviewModal,
  } = useDisclosure(false)
  const onPreviewImage = useCallback(
    (id) => () => {
      setPreviewImage(getImageUrl(id, 0, true))
      setPreviewImageId(id)
      onOpenPreview()
    },
    [getImageUrl, onOpenPreview]
  )

  const onClosePreview = useCallback(() => {
    setPreviewImage(null)
    setPreviewImageId(null)
    onClosePreviewModal()
  }, [onClosePreviewModal])

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="full">
        <ModalOverlay />
        <ModalContent borderRadius="none">
          <ModalHeader>
            <Heading size="lg">
              {selectable ? 'Burn-to-mint' : `${blocks.length} Blocks`}
            </Heading>
            <BePatientMessage />
            {/* <Text>Blocks total: {blocks.length}</Text> */}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mx="auto" px="5">
              {selectable && (
                <Box m="4">
                  <Collapse in={selectedBlocks.length < 2} animateOpacity>
                    <Heading size="md" textAlign="right">
                      You need to select at least 2 blocks to burn
                    </Heading>
                  </Collapse>
                  <Collapse in={selectedBlocks.length > 1} animateOpacity>
                    <Heading size="md" textAlign="right">
                      Burn{' '}
                      <Text fontWeight="bold" display="inline">
                        {selectedBlocks.length}
                      </Text>{' '}
                      to mint{' '}
                      <Text fontWeight="bold" display="inline">
                        {selectedBlocks.length - 1}
                      </Text>{' '}
                      blocks
                    </Heading>
                  </Collapse>
                </Box>
              )}
              <Box overflow="hidden">
                {blocks.map((block) => (
                  <Block
                    key={block.id}
                    block={block}
                    onClick={
                      selectable
                        ? onToggleSelect(block.id)
                        : onPreviewImage(block.id)
                    }
                    selectable={selectable}
                    isSelected={selectable && selectedBlocks.includes(block.id)}
                  />
                ))}
              </Box>
            </Box>
            {selectable && (
              <>
                {burnStatus && (
                  <Box textAlign="center" py="5">
                    {burnStatus === 'loading' && <LoadingApproval />}
                    {burnStatus === 'loading transaction' && (
                      <LoadingTransaction
                        transactionHash={lastTransactionHash}
                      />
                    )}
                    {burnStatus === 'error' && (
                      <LoadingError>{errorMessage}</LoadingError>
                    )}
                  </Box>
                )}
                <Box mx="auto" pt="10" textAlign="center">
                  <Button
                    onClick={onBurn}
                    disabled={
                      burnStatus.includes('loading') ||
                      selectedBlocks.length < 2
                    }
                  >
                    Burn-to-mint
                  </Button>
                </Box>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {previewImage && (
        <BlocksPreview
          isOpen={isOpenPreview}
          onClose={onClosePreview}
          imageUrl={previewImage}
          id={previewImageId}
        />
      )}
    </>
  )
}
export default BlocksView

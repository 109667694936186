import { useContext, useEffect, useMemo, useState } from 'react'
import { Box, Heading, Link, Text } from '@chakra-ui/layout'
import { Modal } from '@chakra-ui/modal'
import { Select } from '@chakra-ui/select'
import PanelContext from 'context/PanelContext'
import { PANEL_BY_SIZE } from 'enums/panels'
import PanelContainer from 'components/atoms/PanelContainer'
import { PANELS_URL } from 'enums/dotenv'
import PanelContainerModal from 'components/templates/PanelContainerModal'
import Properties from 'components/molecules/Properties'
import BePatientMessage from 'components/atoms/BePatientMessage'
import { getOpenSeaUrl } from 'utils/getUrl'

function PanelsView({ onClose, isOpen }) {
  const { panelList } = useContext(PanelContext)
  const [attributes, setAttributes] = useState([])
  const [panelSelected, setPanelSelected] = useState(panelList[0])
  const [selectValue, setSelectValue] = useState(0)
  const [columns, rows] = useMemo(
    () => PANEL_BY_SIZE[panelSelected.size].size.split('x') || [2, 2],
    [panelSelected.size]
  )

  const handleChange = (e) => {
    const value = e.target.value
    setSelectValue(value)
    setPanelSelected(panelList[value])
  }

  useEffect(() => {
    async function getProperties() {
      const config = {
        'Content-Type': 'application/json',
      }
      try {
        const result = await fetch(`${PANELS_URL}/${panelSelected.id}`, config)
        const panelData = await result.json()
        setAttributes(panelData.attributes)
      } catch (e) {
        console.log('erro no fetch of properties', e)
      }
    }

    getProperties()
  }, [panelSelected.id])

  // Preload panel images
  useEffect(() => {
    if (isOpen) {
      panelList.map(({ id }) => {
        const image = new window.Image()
        image.src = `${PANELS_URL}/${id}.png`
        return image
      })
    }
  }, [panelList, isOpen])

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="full">
      <PanelContainerModal
        shouldRenderPanel
        title="My Panels"
        renderSelect={
          <Box
            mb="5"
            ml={{ base: 0, md: 10 }}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
          >
            <Select
              w="xs"
              onChange={handleChange}
              value={selectValue}
              mr="4"
              // mb="5"
            >
              {panelList.map(({ name, degradationLevel, id, size }, index) => (
                <option value={index} key={id}>
                  {name.length > 0 ? name : `Panel #${id}`} [
                  {PANEL_BY_SIZE[size].size}] [degradation: {degradationLevel}
                  /4]
                </option>
              ))}
            </Select>
            <BePatientMessage pt="0" ml="1" />
          </Box>
        }
        renderPanel={
          <>
            <PanelContainer
              columns={columns}
              rows={rows}
              bgImage={`url(${PANELS_URL}/${panelSelected.id}.png)`}
            />

            <Box textAlign="center" py="3">
              <Link
                href={`${getOpenSeaUrl(panelSelected.id, 'panels')}`}
                target="_blank"
                textDecoration="underline"
                mr="5"
              >
                Open on OpenSea
              </Link>
              <Link
                href={`${PANELS_URL}/${panelSelected.id}.png`}
                download={`panel_#${panelSelected.id}.png`}
                target="_blank"
                textDecoration="underline"
                // onClick={(e) => {
                //   e.preventDefault()
                //   downloadFile(
                //     `${BLOCKS_URL}/${id}_ps0.png`,
                //     `block_#${id}.png`
                //   )
                // }}
              >
                Download
              </Link>
            </Box>
          </>
        }
        renderSidePanel={
          <Box display="flex" flexDir="column">
            <Heading size="lg">
              {panelSelected.name || `Panel #${panelSelected.id}`}
            </Heading>
            <Text mt="4" fontSize="lg">
              {panelSelected.description}
            </Text>
            <Properties values={attributes} />
          </Box>
        }
      />
    </Modal>
  )
}
export default PanelsView

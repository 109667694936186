import { useContext } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import CardButton from 'components/molecules/CardButton'
import DescontructPanelModal from 'components/organisms/DescontructPanelModal'
import PanelContext from 'context/PanelContext'
import GlobalContext from 'context/GlobalContext'

export const DescontructPanelSection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const { ethIsEnabled } = useContext(GlobalContext)
  const { filledPanels } = useContext(PanelContext)
  const hasPanels = filledPanels.length > 0

  return (
    <>
      <CardButton
        className="flex-1"
        title="Deconstructing Panels"
        buttonText="Descontruct Panel..."
        buttonProps={{
          onClick: onOpen,
          disabled: !ethIsEnabled || !hasPanels,
          className: `${hasPanels ? 'cursor-pointer' : 'cursor-not-allowed'}`,
        }}
      >
        Panels can be deconstructed and returned to the empty state (all blocks
        are lost). Remember that empty Panels can be filled with blocks again.
        However, after each deconstruction each Panel degrades, and the new
        blocks on it will lose sharpness, definition and colors.
      </CardButton>
      {hasPanels && isOpen && (
        <DescontructPanelModal onClose={onClose} isOpen={isOpen} />
      )}
    </>
  )
}

export default DescontructPanelSection

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { BLOCKS_URL } from 'enums/dotenv'
import EthersContext from 'context/EthersContext'
import { fetchUserBlocksTokens } from 'contracts/blocksCore'
import { fetchUserPanels } from 'contracts/panelsCore'
import GlobalContext from 'context/GlobalContext'

const handlePanelSize = (size) => {
  if (size === 2) return 4
  if (size === 6) return 9
  return size
}
const getImageUrl = (id, panelSize, withBorder = false) =>
  id &&
  typeof panelSize !== 'undefined' &&
  `${BLOCKS_URL}/${id}_ps${handlePanelSize(panelSize)}${
    withBorder ? '' : '_nb'
  }.png`

export const generateBlockObj = (id, thumbSize = 25) => ({
  id,
  thumbnail: getImageUrl(id, thumbSize),
})

const DEFAULT_CONTEXT = {
  blocks: [],
  panelList: [],
  emptyPanels: [],
  filledPanels: [],
  getImageUrl,
  isLoadingBlocks: true,
  refetchBlocks: () => {},
  refetchPanels: () => {},
}

const PanelContext = createContext(DEFAULT_CONTEXT)

export const PanelContextProvider = ({ children }) => {
  const { ethIsEnabled } = useContext(GlobalContext)
  const { blocksCoreContract, userAddress, panelsCoreContract } =
    useContext(EthersContext)
  const [blocks, setBlocks] = useState(DEFAULT_CONTEXT.blocks)
  const [panelList, setPanelList] = useState(DEFAULT_CONTEXT.panelList)
  const [emptyPanels, setEmptyPanels] = useState(DEFAULT_CONTEXT.emptyPanels)
  const [filledPanels, setFilledPanels] = useState(DEFAULT_CONTEXT.filledPanels)
  const [isLoadingBlocks, setIsLoadingBlocks] = useState(
    DEFAULT_CONTEXT.isLoadingBlocks
  )
  const fetchBlocks = useCallback(async () => {
    if (!ethIsEnabled) return
    setIsLoadingBlocks(true)
    const tokenList = await fetchUserBlocksTokens(
      blocksCoreContract,
      userAddress
    )
    const imageListPopulated = tokenList.map((id) => generateBlockObj(id, 25))
    setBlocks(imageListPopulated)
    setIsLoadingBlocks(false)
  }, [blocksCoreContract, ethIsEnabled, userAddress])

  const fetchPanels = useCallback(async () => {
    if (!ethIsEnabled) return
    const panelListResult = await fetchUserPanels(
      panelsCoreContract,
      userAddress
    )
    setEmptyPanels(panelListResult.filter(({ isEmpty }) => isEmpty))
    setFilledPanels(panelListResult.filter(({ isEmpty }) => !isEmpty))
    setPanelList(panelListResult)
  }, [ethIsEnabled, panelsCoreContract, userAddress])

  useEffect(() => {
    if (!ethIsEnabled) return
    fetchBlocks()
    fetchPanels()
  }, [ethIsEnabled, fetchBlocks, fetchPanels])

  return (
    <PanelContext.Provider
      value={{
        ...DEFAULT_CONTEXT,
        blocks,
        isLoadingBlocks,
        panelList,
        emptyPanels,
        filledPanels,
        refetchBlocks: fetchBlocks,
        refetchPanels: fetchPanels,
      }}
    >
      {children}
    </PanelContext.Provider>
  )
}

export default PanelContext

import { ethers } from 'ethers'
import { BLOCKS_CORE_ADDRESS } from 'enums/dotenv'
import _TilesBlocksCore from 'contracts/build/TilesBlocksCore.json'
import { formatUnitToNumber } from 'utils/formatUnit'

export class BlocksCoreContract {
  constructor(signer) {
    if (typeof window.ethereum !== 'undefined') {
      this.contract = new ethers.Contract(
        BLOCKS_CORE_ADDRESS,
        _TilesBlocksCore.abi,
        signer
      )

      return this.contract
    }
    throw new Error('window.ethereum is not defined')
  }
}

function buildBlocksCoreResult(total = 0, limit = 0) {
  return { total, limit }
}
export async function fetchBlocksCore(contract) {
  try {
    const totalMints = await contract?.totalMints()
    const genesisMintLimit = await contract?.genesisMintLimit()

    if (!totalMints || !genesisMintLimit) return buildBlocksCoreResult()

    return buildBlocksCoreResult(
      formatUnitToNumber(totalMints),
      formatUnitToNumber(genesisMintLimit)
    )
  } catch (err) {
    console.log('Error: ', err)
    return buildBlocksCoreResult()
  }
}

export async function fetchUserBlocksTokens(contract, userAddress) {
  if (!userAddress) return []
  try {
    const getTokenPromises = []
    const totalBlocks = parseInt(await contract.balanceOf(userAddress), 10)

    for (let i = totalBlocks - 1; i >= 0; i--) {
      getTokenPromises.push(
        contract
          .tokenOfOwnerByIndex(userAddress, i)
          .then((token) => parseInt(token, 10))
      )
    }
    const tokens = await Promise.all(getTokenPromises)

    return tokens
  } catch (err) {
    console.log('fetchUserBlocksTokens error: ', err)
    return []
  }
}

// price is edited on getAllPanelsPrices()
export const DEFAULT_PANELS = Object.freeze({
  '2x1': {
    totalBlocks: 2,
    price: 0,
    minted: '-',
    limit: 50,
  },
  '2x2': {
    totalBlocks: 4,
    price: '-',
    minted: '-',
    limit: 500,
  },
  '3x2': {
    totalBlocks: 6,
    price: 0,
    minted: '-',
    limit: 200,
  },
  '3x3': {
    totalBlocks: 9,
    price: '-',
    minted: '-',
    limit: 250,
  },
  '4x4': {
    totalBlocks: 16,
    price: '-',
    minted: '-',
    limit: 80,
  },
  '5x5': {
    totalBlocks: 25,
    price: '-',
    minted: '-',
    limit: 20,
  },
  '6x6': {
    totalBlocks: 36,
    price: '-',
    minted: '-',
    limit: 5,
  },
})

export const PANEL_BY_SIZE = Object.freeze(
  Object.keys(DEFAULT_PANELS).reduce(
    (acc, key) => ({
      ...acc,
      [DEFAULT_PANELS[key].totalBlocks]: {
        ...DEFAULT_PANELS[key],
        size: key,
      },
    }),
    {}
  )
)

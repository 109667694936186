import { ethers } from 'ethers'
import { TILES_MINTER_ADDRESS } from 'enums/dotenv'
import _TilesMinter from 'contracts/build/TilesMinter.json'
import { formatUnitToEther } from 'utils/formatUnit'
import { DEFAULT_PANELS } from 'enums/panels'

export class TilesMinterContract {
  constructor(signer) {
    if (typeof window.ethereum !== 'undefined') {
      this.contract = new ethers.Contract(
        TILES_MINTER_ADDRESS,
        _TilesMinter.abi,
        signer
      )

      return this.contract
    }
    throw new Error('window.ethereum is not defined')
  }
}

const promisedPanelsOutput = (panels) =>
  panels.reduce(
    (prev, curr) => ({
      ...prev,
      ...curr,
    }),
    {}
  )

const getAllPanelsPrices = async (contract, oldPanels = DEFAULT_PANELS) => {
  const getPricesPromises = Object.keys(oldPanels).map((key) => {
    const currentObject = { ...oldPanels[key] }

    if (!currentObject.price)
      return Promise.resolve({ [key]: { ...currentObject } })

    return contract
      .getEmptyPanelPrice(currentObject.totalBlocks)
      .then((value) => ({
        [key]: {
          ...currentObject,
          price: formatUnitToEther(value),
        },
      }))
  })

  const panels = await Promise.all(getPricesPromises)
  return promisedPanelsOutput(panels)
}

export async function fetchPanelsPrices(contract, panels) {
  try {
    const panelPrices = getAllPanelsPrices(contract, panels)
    return panelPrices
  } catch (err) {
    console.log('Error: ', err)
    throw new Error("Can't get panels prices.")
  }
}

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import '@fontsource/roboto/latin.css'
import '@fontsource/bodoni-moda/variable-full.css'
import '@fontsource/bodoni-moda/variable-full-italic.css'
import theme from 'foundation/theme.fonts'

import Home from '../Home'

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  )
}

import { useContext, useEffect, useMemo, useState } from 'react'
import { ethers } from 'ethers'
import Button from 'components/atoms/Button'
import EthersContext from 'context/EthersContext'
import LoadingApproval from 'components/atoms/LoadingApproval'
import LoadingTransaction from 'components/atoms/LoadingTransaction'
import LoadingSuccessful from 'components/atoms/LoadingSuccessful'
import LoadingError from 'components/atoms/LoadingError'
import PanelContext from 'context/PanelContext'
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/slider'
import { Box, Text } from '@chakra-ui/layout'

function MintBlockModal() {
  const { tilesMinterContract, blocksMints, refreshData } =
    useContext(EthersContext)
  const { refetchBlocks } = useContext(PanelContext)
  const [mintStatus, setMintStatus] = useState('')
  const [lastTransactionHash, setLastTransactionHash] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [mintQuantity, setMintQuantity] = useState(1)
  const [timeoutInstance, setTimeoutInstance] = useState(1)
  const blocksRest = useMemo(
    () => blocksMints.limit - blocksMints.total,
    [blocksMints.limit, blocksMints.total]
  )

  useEffect(() => {
    if (blocksRest === 0) {
      window.location.reload()
      return
    }

    if (blocksRest < 20 && mintQuantity > blocksRest) {
      setMintQuantity(blocksRest)
    }
  }, [blocksRest, mintQuantity])

  const resetMintStatus = () => {
    const timeout = setTimeout(
      () => {
        if (setMintStatus) {
          setMintStatus('')
          setErrorMessage(null)
        }
      },
      errorMessage ? 10000 : 5000
    )
    refreshData()
    setTimeoutInstance(timeout)
  }
  const mintBlock = async () => {
    setMintStatus('loading')
    clearTimeout(timeoutInstance)
    try {
      const price = parseFloat(blocksMints.price) * mintQuantity
      // const gasLimit = mintQuantity * 200000
      const mint = await tilesMinterContract.mintBlocks(mintQuantity, {
        value: ethers.utils.parseEther(price.toString()),
        // gasLimit: GAS_LIMIT,
      })
      setMintStatus('loading transaction')
      console.log(mint)
      setLastTransactionHash(mint.hash)
      await mint.wait()
      setMintStatus('success')
      refetchBlocks()
      resetMintStatus()
    } catch (error) {
      if (error.message.includes('Total genesis mint limit reached')) {
        setErrorMessage('Total mint limit reached.')
      } else {
        setErrorMessage(error.message)
      }
      setMintStatus('error')
      resetMintStatus()
    }
  }
  const isLoading = mintStatus.includes('loading')

  return (
    <Box pb="5" pt="2">
      <Box pb="10" pl="4" pr="2" display="flex">
        <Slider
          aria-label="mint-slider"
          defaultValue={1}
          min={1}
          max={blocksRest > 19 ? 20 : blocksRest}
          step={1}
          value={mintQuantity}
          onChange={setMintQuantity}
        >
          <SliderTrack bg="blackAlpha.300">
            <SliderFilledTrack bg="black" />
          </SliderTrack>
          <SliderThumb boxSize={8} borderRadius="none">
            {mintQuantity}
          </SliderThumb>
        </Slider>
        <Text minW="100px" textAlign="right">
          ETH <b>{(parseFloat(blocksMints.price) * mintQuantity).toFixed(2)}</b>
        </Text>
      </Box>
      <div className="w-full text-center">
        <Button
          className="px-8 py-5 text-white text-sm bg-black disabled:opacity-50 disabled:cursor-wait"
          onClick={mintBlock}
          disabled={isLoading}
        >
          Mint Block{mintQuantity > 1 ? 's' : ''}
        </Button>
        {mintStatus === 'loading' && <LoadingApproval />}
        {mintStatus === 'loading transaction' && (
          <LoadingTransaction transactionHash={lastTransactionHash} />
        )}
        {mintStatus === 'success' && <LoadingSuccessful />}
        {mintStatus === 'error' && <LoadingError>{errorMessage}</LoadingError>}
      </div>
    </Box>
  )
}

export default MintBlockModal

import { Text, Heading } from '@chakra-ui/layout'
import Button from 'components/atoms/Button'

function CardButton({
  title,
  buttonText,
  buttonProps = {},
  children,
  className,
}) {
  const justifyButtonTextClass = buttonText ? 'justify-between' : ''

  return (
    <article
      className={`${className} text-center mx-4 flex flex-col ${justifyButtonTextClass} mb-14`}
    >
      <Heading size="lg">{title}</Heading>
      <Text my="8">{children}</Text>
      {buttonText && (
        <div>
          <Button {...buttonProps}>{buttonText}</Button>
        </div>
      )}
    </article>
  )
}

export default CardButton

import { Text } from '@chakra-ui/layout'
import { SlideFade } from '@chakra-ui/transition'

const LoadingError = ({ children }) => (
  <SlideFade in offsetY="20px">
    <Text pt="4" textColor="red.500" size="sm">
      {children}
    </Text>
  </SlideFade>
)

export default LoadingError

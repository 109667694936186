import { Text } from '@chakra-ui/layout'

const BePatientMessage = (props) => {
  return (
    <Text
      fontSize={{ base: 'xs', md: 'sm' }}
      fontWeight="bold"
      fontStyle="italic"
      textAlign="center"
      pt={4}
      {...props}
    >
      Blocks and Panels may take some time to be generated. Be patient.
    </Text>
  )
}

export default BePatientMessage

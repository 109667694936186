import { createContext, useEffect, useState } from 'react'

const DEFAULT_CONTEXT = {
  ethIsEnabled: false,
}

const GlobalContext = createContext(DEFAULT_CONTEXT)

export const GlobalContextProvider = ({ children }) => {
  const [ethIsEnabled, setEthIsEnabled] = useState(DEFAULT_CONTEXT.ethIsEnabled)

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      setEthIsEnabled(true)
    }
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        ...DEFAULT_CONTEXT,
        ethIsEnabled,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext

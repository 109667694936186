import { FormControl, FormHelperText } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Textarea } from '@chakra-ui/textarea'

const INPUT_SIZE = {
  name: 36,
  description: 255,
}

const PanelForm = ({ onChangeValue, isDisabled = false, formValues }) => {
  const onChangeText = (field) => (e) => {
    const { value = '' } = e.target
    onChangeValue(field, value)
  }

  return (
    <>
      <FormControl id="name">
        <Input
          type="text"
          placeholder="Name"
          borderColor="black"
          maxLength={INPUT_SIZE.name}
          onChange={onChangeText('name')}
          value={formValues.name}
          disabled={isDisabled}
        />
        <FormHelperText pl="1" textColor="black">
          {formValues.name.length}/{INPUT_SIZE.name}
        </FormHelperText>
      </FormControl>
      <FormControl id="description" mt="5">
        <Textarea
          type="description"
          borderColor="black"
          resize="vertical"
          placeholder="Description"
          maxLength={INPUT_SIZE.description}
          onChange={onChangeText('description')}
          value={formValues.description}
          disabled={isDisabled}
        />
        <FormHelperText textColor="black" pl="1">
          {formValues.description.length}/{INPUT_SIZE.description}
        </FormHelperText>
      </FormControl>
    </>
  )
}

export default PanelForm

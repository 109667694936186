function Button({ className, small = false, outlined = false, ...otherProps }) {
  const smallButton = 'bg-white text-black border border-black py-2 px-4'
  const normalButton = 'bg-black text-white py-5 px-8 border border-black'
  const reversed = 'bg-white border border-black text-black py-5 px-8'

  let classes = small ? smallButton : normalButton
  if (outlined) classes = reversed
  if (otherProps.disabled) classes += ' disabled:cursor-not-allowed'

  return (
    <button
      className={`${classes} text-sm disabled:opacity-50 ${className}`}
      {...otherProps}
    />
  )
}

export default Button

import { Text } from '@chakra-ui/layout'
import { SlideFade } from '@chakra-ui/transition'

const LoadingApproval = () => (
  <SlideFade in offsetY="20px">
    <Text pt="4" textColor="gray-600" size="sm">
      Approve your transaction on metamask...
    </Text>
  </SlideFade>
)

export default LoadingApproval

import { Button } from '@chakra-ui/button'
import { CloseIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/layout'

const Toast = ({ onClose, children, noClose = false }) => (
  <Box color="white" p={3} pr="20px" bgColor="black">
    {!noClose && (
      <Button
        right="5"
        top="5"
        position="absolute"
        bgColor="transparent"
        onClick={onClose}
        _hover={{ bgColor: 'black' }}
      >
        <CloseIcon color="white" />
      </Button>
    )}
    {children}
  </Box>
)

export default Toast

import { useMemo } from 'react'
import { Grid } from '@chakra-ui/layout'
import { ASSETS_URL } from 'enums/dotenv'
import usePanelWidth from 'foundation/hooks/usePanelWidth'

const PanelContainer = ({ columns = 1, rows = 1, children = '', bgImage }) => {
  const [panelWidth, panelBorderValues] = usePanelWidth()
  const isSquare = useMemo(() => columns === rows, [columns, rows])
  const totalBlocks = useMemo(() => columns * rows, [columns, rows])
  const bg = useMemo(() => {
    if (bgImage) return bgImage

    const squareTotal = columns * rows
    if (squareTotal === 2 || squareTotal === 6)
      return `url(${ASSETS_URL}/panelBorder${squareTotal}.png)`
    return `url(${ASSETS_URL}/panelBorder.png)`
  }, [bgImage, columns, rows])
  const heightAddition = totalBlocks === 6 ? 9 : 0

  const sizes = {
    base: isSquare ? panelWidth : panelWidth - panelBorderValues * 2,
    lg: panelWidth,
  }

  const widths = {
    base: `${sizes.base}px`,
    lg: `${sizes.lg}px`,
  }

  const heights = {
    base: `${Math.floor((panelWidth / columns) * rows - heightAddition)}px`,
    lg: `${Math.floor((sizes.lg / columns) * rows)}px`,
  }

  return (
    <Grid
      bgImage={bg}
      bgSize="cover"
      bgRepeat="no-repeat"
      p={`${panelBorderValues}px`}
      boxSizing={{ base: 'border-box', lg: 'content-box' }}
      width={widths}
      height={heights}
      maxWidth="full"
      mx="auto"
      templateColumns={`repeat(${columns}, 1fr)`}
      templateRows={`repeat(${rows}, 1fr)`}
      mb={{ base: 0, lg: '90px' }}
    >
      {children}
    </Grid>
  )
}
export default PanelContainer

import { Heading, Text } from '@chakra-ui/layout'

function Footer() {
  return (
    <footer className="px-4 py-12 bg-gray-100">
      <Heading size="md">The Panels</Heading>
      <Text my="8">A generative art experiment</Text>
      <Text>
      This project is a collab between  Flamingo DAO and Arihz, an anonymous computer scientist and creative coder. He/she is also
        the creator of Framergence, Pulsquares and Avid Lines.
      </Text>
    </footer>
  )
}

export default Footer

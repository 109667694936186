import { ethers } from 'ethers'
import { PANELS_CORE_ADDRESS } from 'enums/dotenv'
import _PanelsCore from 'contracts/build/TilesPanelsCore.json'
import { formatUnitToNumber } from 'utils/formatUnit'
import { DEFAULT_PANELS } from 'enums/panels'

export class PanelsCoreContract {
  constructor(signer) {
    if (typeof window.ethereum !== 'undefined') {
      this.contract = new ethers.Contract(
        PANELS_CORE_ADDRESS,
        _PanelsCore.abi,
        signer
      )

      return this.contract
    }
    throw new Error('window.ethereum is not defined')
  }
}

const promisedPanelsOutput = (panels) =>
  panels.reduce(
    (prev, curr) => ({
      ...prev,
      ...curr,
    }),
    {}
  )

const getAllPanelsMinted = async (contract, oldPanels = DEFAULT_PANELS) => {
  try {
    const getMintedPromises = Object.keys(oldPanels).map((key) => {
      const currentObject = { ...oldPanels[key] }

      return contract?.totalMints(currentObject.totalBlocks).then((value) => ({
        [key]: {
          ...currentObject,
          minted: formatUnitToNumber(value),
        },
      }))
    })

    const panels = await Promise.all(getMintedPromises)
    return promisedPanelsOutput(panels)
  } catch (error) {
    console.log('Error on getAllPanelsMinted', error)
    return Promise.resolve()
  }
}

export async function fetchPanelsMinted(contract, panels) {
  try {
    const panelsMinted = getAllPanelsMinted(contract, panels)
    return panelsMinted
  } catch (err) {
    console.log('Error: ', err)
    throw new Error("Can't get panels total mints.")
  }
}

export async function fetchUserPanelsTokens(contract, userAddress) {
  if (!userAddress) return []
  try {
    const getTokenPromises = []
    const totalPanels = parseInt(await contract.balanceOf(userAddress), 10)

    for (let i = totalPanels - 1; i >= 0; i--) {
      getTokenPromises.push(
        contract
          .tokenOfOwnerByIndex(userAddress, i)
          .then((token) => parseInt(token, 10))
      )
    }
    const tokens = await Promise.all(getTokenPromises)

    return tokens
  } catch (err) {
    console.log('Error: ', err)
    return []
  }
}

export async function fetchUserPanels(contract, userAddress) {
  try {
    const panelsTokens = await fetchUserPanelsTokens(contract, userAddress)

    const getPanelPromises = panelsTokens.map((id) =>
      contract.tokens(id).then((panel) => ({ ...panel, id }))
    )

    const panels = await Promise.all(getPanelPromises)
    return panels
  } catch (err) {
    console.log('fetchUserPanels error: ', err)
    return []
  }
}

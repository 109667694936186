import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { BlocksCoreContract, fetchBlocksCore } from 'contracts/blocksCore'
import { fetchPanelsPrices, TilesMinterContract } from 'contracts/tilesMinter'
import { Provider } from 'contracts/provider'
import { DEFAULT_PANELS } from 'enums/panels'
import { fetchPanelsMinted, PanelsCoreContract } from 'contracts/panelsCore'
import GlobalContext from 'context/GlobalContext'
import { REFRESH_INTERVAL } from 'enums/dotenv'

const DEFAULT_CONTEXT = {
  blocksMints: {
    total: 0,
    limit: 0,
    price: '0.05',
  },
  panelsInfo: DEFAULT_PANELS,
  user: {
    adress: '',
    signer: {},
  },
  provider: null,
  providerInstance: null,
  blocksCoreContract: null,
  tilesMinterContract: null,
  panelsCoreContract: null,
  userAddress: '',
  refreshData: () => {},
  isEmergence: false,
  isAdmin: false,
}

const EthersContext = createContext(DEFAULT_CONTEXT)

let providerInstance,
  provider,
  blocksCoreContract,
  tilesMinterContract,
  panelsCoreContract

const getEtherInstances = async (ethIsEnabled) => {
  if (ethIsEnabled && !provider) {
    try {
      providerInstance = new Provider()
      await providerInstance.initialize()
      provider = providerInstance.get()
      const signer = await provider.getSigner()
      blocksCoreContract = new BlocksCoreContract(signer)
      tilesMinterContract = new TilesMinterContract(signer)
      panelsCoreContract = new PanelsCoreContract(signer)
    } catch (error) {
      console.log('Error on getEtherInstances', error)
    }
  }

  return {
    providerInstance,
    provider,
    blocksCoreContract,
    tilesMinterContract,
    panelsCoreContract,
  }
}

export const EthersContextProvider = ({ children }) => {
  const { ethIsEnabled } = useContext(GlobalContext)
  const [providerInstance, setProviderInstance] = useState(
    DEFAULT_CONTEXT.providerInstance
  )
  const [provider, setProvider] = useState(DEFAULT_CONTEXT.provider)
  const [blocksCoreContract, setBlocksCoreContract] = useState(
    DEFAULT_CONTEXT.blocksCoreContract
  )
  const [tilesMinterContract, setTilesMinterContract] = useState(
    DEFAULT_CONTEXT.tilesMinterContract
  )
  const [panelsCoreContract, setPanelsCoreContract] = useState(
    DEFAULT_CONTEXT.panelsCoreContract
  )
  const [blocksMints, setBlocksMints] = useState(DEFAULT_CONTEXT.blocksMints)
  const [panelsInfo, setPanelsInfo] = useState(DEFAULT_CONTEXT.panelsInfo)
  const [userAddress, setUserAddress] = useState(DEFAULT_CONTEXT.userAddress)
  const [isEmergence, setIsEmergence] = useState(DEFAULT_CONTEXT.isEmergence)
  const [isAdmin, setIsAdmin] = useState(DEFAULT_CONTEXT.isAdmin)

  const setBlocksCore = useCallback(async () => {
    if (!ethIsEnabled) return
    const { total, limit } = await fetchBlocksCore(blocksCoreContract)
    setBlocksMints((state) => {
      if (state.total === total && state.limit === limit) return state
      return {
        ...state,
        total,
        limit,
      }
    })
  }, [blocksCoreContract, ethIsEnabled])

  const setTilesMinter = useCallback(async () => {
    if (!ethIsEnabled) return
    const panelsMinted = await fetchPanelsMinted(
      panelsCoreContract,
      DEFAULT_PANELS
    )
    const panelsCompleted = await fetchPanelsPrices(
      tilesMinterContract,
      panelsMinted
    )

    setPanelsInfo((state) => {
      let shouldUpdate = false
      Object.keys(panelsCompleted).forEach((key) => {
        if (!shouldUpdate && panelsCompleted[key].minted !== state[key].minted)
          shouldUpdate = true
      })
      if (shouldUpdate) return panelsCompleted
      return state
    })
  }, [ethIsEnabled, panelsCoreContract, tilesMinterContract])

  const refreshData = useCallback(() => {
    if (!ethIsEnabled) return
    setBlocksCore()
    setTilesMinter()
  }, [ethIsEnabled, setBlocksCore, setTilesMinter])

  useEffect(() => {
    if (!ethIsEnabled) return
    refreshData()
    const interval = setInterval(() => {
      refreshData()
    }, REFRESH_INTERVAL)
    return () => clearInterval(interval)
  }, [refreshData, ethIsEnabled])

  useEffect(() => {
    if (!ethIsEnabled) return
    async function setInstances() {
      const instances = await getEtherInstances(ethIsEnabled)
      setProviderInstance(instances.providerInstance)
      setProvider(instances.provider)
      setBlocksCoreContract(instances.blocksCoreContract)
      setTilesMinterContract(instances.tilesMinterContract)
      setPanelsCoreContract(instances.panelsCoreContract)
    }
    setInstances()
  }, [ethIsEnabled])

  useEffect(() => {
    if (!ethIsEnabled || !provider) return
    async function setUserInfo() {
      console.log(provider)
      const signer = await provider?.getSigner()
      const address = await signer?.getAddress()
      const adminAddress = await tilesMinterContract?.adminAddress()
      let resultIsEmergence = false
      try {
        resultIsEmergence = await tilesMinterContract?.isEmergence(address)
      } catch (error) {
        console.log('Error on tilesMinterContract?.isEmergence', error)
      }

      setUserAddress(address)
      setIsAdmin(
        typeof adminAddress !== 'undefined' && adminAddress === address
      )
      setIsEmergence(resultIsEmergence)
    }
    setUserInfo()
  }, [ethIsEnabled, provider, tilesMinterContract])

  return (
    <EthersContext.Provider
      value={{
        ...DEFAULT_CONTEXT,
        providerInstance,
        blocksMints,
        panelsInfo,
        provider,
        blocksCoreContract,
        tilesMinterContract,
        panelsCoreContract,
        userAddress,
        refreshData,
        isEmergence,
        isAdmin,
      }}
    >
      {children}
    </EthersContext.Provider>
  )
}

export default EthersContext

import { useCallback, useEffect, useState } from 'react'
import { Box, Heading, Link } from '@chakra-ui/layout'
import { Modal } from '@chakra-ui/modal'
import { Image } from '@chakra-ui/image'
import { Skeleton } from '@chakra-ui/react'
import usePanelWidth from 'foundation/hooks/usePanelWidth'
import { BLOCKS_URL } from 'enums/dotenv'
import Properties from 'components/molecules/Properties'
import PanelContainerModal from 'components/templates/PanelContainerModal'
import { getOpenSeaUrl } from 'utils/getUrl'

function BlocksPreview({ onClose, isOpen, imageUrl, id }) {
  const [panelWidth] = usePanelWidth()
  const [image, setImage] = useState(null)
  const [attributes, setAttributes] = useState([])

  const loadImage = useCallback(() => {
    const windowImage = new window.Image()
    windowImage.src = imageUrl
    windowImage.onload = () => {
      setImage(imageUrl)
    }
  }, [imageUrl])

  useEffect(() => {
    async function getProperties() {
      const config = {
        'Content-Type': 'application/json',
      }
      try {
        const result = await fetch(`${BLOCKS_URL}/${id}`, config)
        const panelData = await result.json()
        setAttributes(panelData.attributes)
      } catch (e) {
        console.log('erro no fetch of propertys', e)
      }
    }

    getProperties()
  }, [id])

  useEffect(() => {
    loadImage()
  }, [loadImage])

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="full">
      <PanelContainerModal
        shouldRenderPanel
        title="Block Preview"
        renderPanel={
          <Box w={panelWidth} m="auto" mt="16" pb="5" maxW="80%" maxH="80%">
            <Skeleton isLoaded={image} w="100%">
              <Image boxSize="full" src={image} />
              <Box textAlign="center" py="3">
                <Link
                  href={`${getOpenSeaUrl(id, 'blocks')}`}
                  target="_blank"
                  textDecoration="underline"
                  mr="5"
                >
                  Open on OpenSea
                </Link>
                <Link
                  href={`${BLOCKS_URL}/${id}_ps0.png`}
                  download={`block_#${id}.png`}
                  target="_blank"
                  textDecoration="underline"
                >
                  Download
                </Link>
              </Box>
            </Skeleton>
          </Box>
        }
        renderSidePanel={
          <Skeleton isLoaded={image} w="full">
            <Box display="flex" flexDir="column">
              <Heading size="lg">{`Block #${id}`}</Heading>
              <Properties values={attributes} />
            </Box>
          </Skeleton>
        }
      />
    </Modal>
  )
}
export default BlocksPreview

import { useCallback, useContext, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { useToast } from '@chakra-ui/toast'
import { Text } from '@chakra-ui/layout'
// import { GAS_LIMIT } from 'enums/dotenv'
import PanelContext from 'context/PanelContext'
import CardButton from 'components/molecules/CardButton'
import BlocksView from 'components/organisms/BlocksView'
import Toast from 'components/atoms/Toast'
import EthersContext from 'context/EthersContext'
import GlobalContext from 'context/GlobalContext'

export const BurnBlocks = () => {
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const { ethIsEnabled } = useContext(GlobalContext)
  const { blocks, refetchBlocks } = useContext(PanelContext)
  const { tilesMinterContract, blocksMints } = useContext(EthersContext)
  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [burnStatus, setBurnStatus] = useState('')
  const [lastTransactionHash, setLastTransactionHash] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [timeoutInstance, setTimeoutInstance] = useState(1)
  const toast = useToast()

  const excedsLimitToast = useCallback(() => {
    toast({
      position: 'top-right',
      render: ({ onClose: onCloseToast }) => (
        <Toast onClose={onCloseToast}>
          <Text>The limit to burn is 9 blocks</Text>
          <Text>Remove your selected one</Text>
        </Toast>
      ),
      duration: 3000,
      isClosable: true,
    })
  }, [toast])

  const onToggleSelect = useCallback(
    (id) => () => {
      const isIncluded = selectedBlocks.includes(id)
      if (!isIncluded && selectedBlocks.length >= 9) {
        excedsLimitToast()
        return
      }
      if (isIncluded) {
        setSelectedBlocks(selectedBlocks.filter((blockId) => blockId !== id))
        return
      }

      setSelectedBlocks([...selectedBlocks, id])
    },
    [excedsLimitToast, selectedBlocks]
  )

  const onCloseBlocksView = () => {
    onClose()
    setSelectedBlocks([])
  }

  const resetBurnStatus = () => {
    const timeout = setTimeout(
      () => {
        if (setBurnStatus) {
          setBurnStatus('')
          setErrorMessage(null)
        }
      },
      errorMessage ? 30000 : 5000
    )
    setTimeoutInstance(timeout)
    refetchBlocks()
  }

  const onBurn = async () => {
    setBurnStatus('loading')
    clearTimeout(timeoutInstance)

    try {
      const burnResult = await tilesMinterContract.burnToMintBlocks(
        selectedBlocks,
        {
          value: 0,
          // gasLimit: GAS_LIMIT,
        }
      )
      setBurnStatus('loading transaction')
      setLastTransactionHash(burnResult.hash)
      await burnResult.wait()
      toast({
        position: 'top-right',
        render: ({ onClose: onCloseToast }) => (
          <Toast onClose={onCloseToast}>
            <Text>Blocks burnt sucessfully!</Text>
            <Text>Check it after few minutes</Text>
          </Toast>
        ),
        duration: 30000,
        isClosable: true,
      })
      onCloseBlocksView()
      setBurnStatus('')
      resetBurnStatus()
    } catch (error) {
      console.log(error)
      setBurnStatus('error')
      setErrorMessage(error.message)
      resetBurnStatus()
    }
  }
  const hasBlocks = blocks.length > 0

  return (
    <>
      <CardButton
        className="flex-1"
        title="Burn-to-mint Blocks"
        buttonText="Burn Blocks..."
        buttonProps={{
          onClick: onOpen,
          disabled:
            !ethIsEnabled ||
            !hasBlocks ||
            parseInt(blocksMints.total, 10) < parseInt(blocksMints.limit, 10),
          className: `${hasBlocks ? 'cursor-pointer' : 'cursor-not-allowed'}`,
        }}
      >
        Apart from burning Blocks to mint Panels, it's also possible to burn
        Blocks to get new Blocks. This mechanism is called burn-to-mint, and was
        first introduced in the Framergence project. You burn N Blocks to mint
        N-1 new Blocks.
      </CardButton>
      {isOpen && (
        <BlocksView
          onClose={onCloseBlocksView}
          isOpen={isOpen}
          selectable
          onToggleSelect={onToggleSelect}
          selectedBlocks={selectedBlocks}
          onBurn={onBurn}
          burnStatus={burnStatus}
          lastTransactionHash={lastTransactionHash}
          errorMessage={errorMessage}
        />
      )}
    </>
  )
}

export default BurnBlocks

import { Heading, Text } from '@chakra-ui/layout'

function Card({ title, image, alt, figcaption, children, className }) {
  return (
    <article
      className={`${className} text-center mx-5 flex justify-between flex-col`}
    >
      <div>
        <Heading as="h3" size="lg" mx="5">
          {title}
        </Heading>
        <Text my="8">{children}</Text>
      </div>
      <figure className="text-center">
        <img src={image} alt={alt} className="w-100 inline-block" />
        <figcaption className="block my-4 text-sm">{figcaption}</figcaption>
      </figure>
    </article>
  )
}

export default Card

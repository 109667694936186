import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from '@chakra-ui/layout'
import PanelContext from 'context/PanelContext'
import BlockPanel from '../BlockPanel'
import PanelContainer from '../PanelContainer'

const uniqByProp = (prop) => (arr) =>
  Object.values(
    arr.reduce(
      (acc, item) =>
        item && item[prop]
          ? { ...acc, [item[prop]]: item } // just include items with the prop
          : acc,
      {}
    )
  )

const Panel = ({
  columns = 0,
  rows = 0,
  totalBlocks = 0,
  onChangePanel,
  panel,
  isDisabled = false,
  blocks = null,
}) => {
  const { blocks: contextBlocks, getImageUrl } = useContext(PanelContext)
  const [usedImages, setUsedImages] = useState([])

  const allBlocks = useMemo(
    () => uniqByProp('id')(blocks || contextBlocks),
    [blocks, contextBlocks]
  )

  const onFilled = useCallback(
    (pos) => (image) => {
      const newPanel = [...panel]
      const posSelected = newPanel[pos]
      if (posSelected || !image) {
        setUsedImages(
          usedImages.filter((imageId) => imageId !== posSelected.id)
        )
      } else {
        setUsedImages((usedImagesState) => [...usedImagesState, image.id])
      }
      newPanel[pos] = image ? image : null
      onChangePanel(newPanel)
    },
    [onChangePanel, panel, usedImages]
  )

  useEffect(() => {
    setUsedImages([])
  }, [totalBlocks])

  const renderBlockPanel = useMemo(() => {
    const usedBlocksId = panel.map((block) => block?.id ?? null)
    const blocksToSelect = allBlocks.filter(
      ({ id }) => !usedImages.includes(id) && !usedBlocksId.includes(id)
    )

    return panel.map((block, index) => {
      return (
        <BlockPanel
          key={index}
          onFilled={onFilled(index)}
          numberOfBlocks={totalBlocks}
          blocks={blocksToSelect}
          getImageUrl={getImageUrl}
          isDisabled={isDisabled}
          block={block}
        />
      )
    })
  }, [
    panel,
    onFilled,
    totalBlocks,
    allBlocks,
    getImageUrl,
    isDisabled,
    usedImages,
  ])

  return (
    <>
      <Text textAlign="center" fontStyle="italic">
        Click to choose a Block
      </Text>
      <PanelContainer columns={columns} rows={rows}>
        {renderBlockPanel}
      </PanelContainer>
    </>
  )
}
export default Panel

import { Link, Text } from '@chakra-ui/layout'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { SlideFade } from '@chakra-ui/transition'

const LoadingTransaction = ({ transactionHash }) => (
  <SlideFade in offsetY="20px">
    <Text pt="4" textColor="black" size="sm">
      Waiting transaction: #
      <Link
        isExternal
        href={`https://etherscan.io/tx/${transactionHash}`}
        target="_blank"
        rel="noreferrer"
      >
        {`${transactionHash.slice(0, 5)}...${transactionHash.slice(-5)}`}
        <ExternalLinkIcon mx="2px" />
      </Link>
    </Text>
  </SlideFade>
)

export default LoadingTransaction

const { useBreakpointValue } = require('@chakra-ui/media-query')

const baseWidth = 840
const baseBorder = 21
const quotient = (baseBorder * 100) / baseWidth

const getBorderValue = (width) => (width * quotient) / 100

const panelWidthValues = {
  base: 320,
  sm: 430,
  md: 700,
  lg: baseWidth,
}

const panelBorderValues = {
  base: getBorderValue(panelWidthValues.base),
  sm: getBorderValue(panelWidthValues.sm),
  md: getBorderValue(panelWidthValues.md),
  lg: baseBorder,
}

export const usePanelWidth = () => {
  const panelWidth = useBreakpointValue(panelWidthValues)
  const panelBorderSize = useBreakpointValue(panelBorderValues)

  return [panelWidth, panelBorderSize, { panelWidthValues, panelBorderSize }]
}

export default usePanelWidth

import { Text } from '@chakra-ui/layout'
import { SlideFade } from '@chakra-ui/transition'

const LoadingSuccessful = () => (
  <SlideFade in offsetY="20px">
    <Text pt="4" textColor="green.700" size="sm">
      Minted successfully!
    </Text>
  </SlideFade>
)

export default LoadingSuccessful

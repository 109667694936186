import { useContext, useEffect, useMemo, useState } from 'react'
import { Box, Heading, Text } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { useToast } from '@chakra-ui/toast'
import { Select } from '@chakra-ui/select'
import PanelContext from 'context/PanelContext'
import { PANEL_BY_SIZE } from 'enums/panels'
import usePanelWidth from 'foundation/hooks/usePanelWidth'
import PanelContainer from 'components/atoms/PanelContainer'
// import { GAS_LIMIT, PANELS_URL } from 'enums/dotenv'
import { PANELS_URL } from 'enums/dotenv'
import Button from 'components/atoms/Button'
import LoadingTransaction from 'components/atoms/LoadingTransaction'
import LoadingError from 'components/atoms/LoadingError'
import LoadingApproval from 'components/atoms/LoadingApproval'
import Toast from 'components/atoms/Toast'
import EthersContext from 'context/EthersContext'

function DescontructPanelModal({ onClose, isOpen }) {
  const { refetchPanels, filledPanels } = useContext(PanelContext)
  const { tilesMinterContract } = useContext(EthersContext)
  const [panelWidth] = usePanelWidth()
  const [panelSelected, setPanelSelected] = useState(filledPanels[0])
  const [selectValue, setSelectValue] = useState(0)
  const [descontructStatus, setDescontructStatus] = useState('')
  const [lastTransactionHash, setLastTransactionHash] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [timeoutInstance, setTimeoutInstance] = useState(1)
  const toast = useToast()
  const [columns, rows] = useMemo(
    () => PANEL_BY_SIZE[panelSelected?.size]?.size.split('x') || [2, 2],
    [panelSelected?.size]
  )

  const resetMintStatus = () => {
    const timeout = setTimeout(
      () => {
        if (setDescontructStatus) {
          setDescontructStatus('')
          setErrorMessage(null)
        }
      },
      errorMessage ? 30000 : 5000
    )
    setTimeoutInstance(timeout)
    refetchPanels()
  }

  const handleChange = (e) => {
    setSelectValue(e.target.value)
    setPanelSelected(filledPanels[e.target.value])
  }

  const descontructPanel = async () => {
    setDescontructStatus('loading')
    clearTimeout(timeoutInstance)

    try {
      const mint = await tilesMinterContract.deconstructPanel(
        panelSelected.id,
        {
          value: 0,
        }
      )
      setDescontructStatus('loading transaction')
      setLastTransactionHash(mint.hash)
      await mint.wait()
      toast({
        position: 'top-right',
        render: ({ onClose: onCloseToast }) => (
          <Toast onClose={onCloseToast}>
            <Text>Panel deconstruct sucessfully!</Text>
            <Text>Check it on Fill Empty Panel</Text>
          </Toast>
        ),
        duration: 30000,
        isClosable: true,
      })
      resetMintStatus()
      onClose()
    } catch (error) {
      console.log(error)
      setDescontructStatus('error')
      setErrorMessage(error.message)
      resetMintStatus()
    }
  }
  const isLoading = descontructStatus.includes('loading')

  // Preload panel images
  useEffect(() => {
    if (isOpen) {
      filledPanels.map(({ id }) => {
        const image = new window.Image()
        image.src = `${PANELS_URL}/${id}.png`
        return image
      })
    }
  }, [filledPanels, isOpen])

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="full">
      <ModalOverlay />
      <ModalContent borderRadius="none">
        <ModalHeader>
          <Heading size="lg">Descontruct Panel</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <>
            <Select
              variant="flushed"
              onChange={handleChange}
              value={selectValue}
              width={panelWidth}
              maxW="full"
              mx="auto"
            >
              {filledPanels.map(
                ({ name, degradationLevel, id, size }, index) => (
                  <option value={index} key={id}>
                    {name.length > 0 ? name : `Panel #${id}`} [
                    {PANEL_BY_SIZE[size].size}] [degradation: {degradationLevel}
                    /4]
                  </option>
                )
              )}
            </Select>
            {panelSelected.size && (
              <>
                <PanelContainer
                  columns={columns}
                  rows={rows}
                  bgImage={`url(${PANELS_URL}/${panelSelected.id}.png)`}
                />
                <Box w={panelWidth} mx="auto" pt="5">
                  <Box display="flex" flexDir="column">
                    <Heading size="md">{panelSelected.name}</Heading>
                    <Text mt="4">{panelSelected.description}</Text>
                  </Box>
                </Box>
                {descontructStatus && (
                  <Box textAlign="center" py="5">
                    {descontructStatus === 'loading' && <LoadingApproval />}
                    {descontructStatus === 'loading transaction' && (
                      <LoadingTransaction
                        transactionHash={lastTransactionHash}
                      />
                    )}
                    {descontructStatus === 'error' && (
                      <LoadingError>{errorMessage}</LoadingError>
                    )}
                  </Box>
                )}
                <Box textAlign="center" px="2" mt="5" alignSelf="flex-end">
                  <Text>
                    After clicking the button below, the Panel will be
                    deconstructed and returned to the empty state. It will also
                    degrade one level.
                  </Text>
                  <Text fontWeight="bold" fontStyle="italic" mb="5">
                    All blocks on it will be lost.
                  </Text>
                  <Button disabled={isLoading} onClick={descontructPanel}>
                    Descontruct this Panel
                  </Button>
                </Box>
              </>
            )}
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
export default DescontructPanelModal

import Web3Modal from 'web3modal'
import { providers } from 'ethers'
import WalletConnectProvider from '@walletconnect/web3-provider'

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: '81b7b4f7fabd4ea08766c9e1640f786d', // required
    },
  },
}

const onChangeNetwork = (newNetwork, oldNetwork) => {
  if (oldNetwork) {
    window.location.reload()
  }
}

export class Provider {
  constructor() {
    this.provider = null
  }
  async initialize() {
    try {
      const web3Modal = new Web3Modal({
        cacheProvider: true, // optional
        providerOptions, // required
      })
      let prov = await web3Modal.connect()
      this.provider = new providers.Web3Provider(prov)

      prov
        .on('network', onChangeNetwork)
        .on('chainChanged', onChangeNetwork)
        .on('accountsChanged', () => {
          window.location.reload()
        })
    } catch (error) {
      console.log('Error on initialize provider', error)
    }
    return this
  }
  async signIn() {
    return await window.ethereum.request({ method: 'eth_requestAccounts' })
  }
  get() {
    return this.provider
  }
}

import { useContext, useMemo, useState } from 'react'
import { ethers } from 'ethers'
import { Modal } from '@chakra-ui/modal'
import { useDisclosure } from '@chakra-ui/hooks'
import Button from 'components/atoms/Button'
import EthersContext from 'context/EthersContext'
import { Select } from '@chakra-ui/select'
import { Box } from '@chakra-ui/react'
import { Text } from '@chakra-ui/layout'
// import { GAS_LIMIT } from 'enums/dotenv'
import { DEFAULT_PANELS } from 'enums/panels'
import LoadingTransaction from 'components/atoms/LoadingTransaction'
import LoadingApproval from 'components/atoms/LoadingApproval'
import LoadingSuccessful from 'components/atoms/LoadingSuccessful'
import LoadingError from 'components/atoms/LoadingError'
import PanelContext from 'context/PanelContext'
import PanelContainerModal from 'components/templates/PanelContainerModal'
import FillPanelModal from '../FillPanelModal'

const isPrivatePanel = (size) =>
  DEFAULT_PANELS[size].totalBlocks === 2 ||
  DEFAULT_PANELS[size].totalBlocks === 6

function MintPanelModal({ canBuild }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const { panelsInfo, refreshData, tilesMinterContract, isAdmin, isEmergence } =
    useContext(EthersContext)
  const { refetchPanels } = useContext(PanelContext)
  const [mintStatus, setMintStatus] = useState('')
  const [panelSize, setPanelSize] = useState('')
  const [lastTransactionHash, setLastTransactionHash] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [timeoutInstance, setTimeoutInstance] = useState(1)
  const options = useMemo(() => {
    return Object.keys(panelsInfo).reduce((acc, curr) => {
      const panel = panelsInfo[curr]
      const panelReachedLimit = parseInt(panel.minted, 10) >= panel.limit
      if (panelReachedLimit) return acc

      if (panel.totalBlocks === 2 && !isAdmin) return acc
      if (panel.totalBlocks === 6 && !isAdmin && !isEmergence) return acc

      return [...acc, { text: curr, value: panel.totalBlocks }]
    }, [])
  }, [isAdmin, isEmergence, panelsInfo])

  const handleChange = (e) => {
    setPanelSize(e.target.value)
  }

  const resetMintStatus = () => {
    const timeout = setTimeout(
      () => {
        if (setMintStatus) {
          setMintStatus('')
          setErrorMessage(null)
        }
      },
      errorMessage ? 30000 : 5000
    )
    setTimeoutInstance(timeout)
    refreshData()
    refetchPanels()
  }

  const mintPanel = async () => {
    setMintStatus('loading')
    clearTimeout(timeoutInstance)
    const panel = panelsInfo[panelSize]

    try {
      const { price, totalBlocks } = panel

      const mint = await tilesMinterContract.mintPanel(
        '',
        '',
        totalBlocks,
        [],
        {
          value: price ? ethers.utils.parseEther(price) : 0,
          // gasLimit: GAS_LIMIT,
        }
      )
      setMintStatus('loading transaction')
      setLastTransactionHash(mint.hash)
      await mint.wait()
      setMintStatus('success')
      resetMintStatus()
      setPanelSize('')
    } catch (error) {
      console.log(error)
      setMintStatus('error')
      setErrorMessage(error.message)
      resetMintStatus()
    }
  }
  const isLoading = mintStatus.includes('loading')

  return (
    <div className="pb-5 pt-2">
      <div className="pb-10">
        <Select
          placeholder="Choose a size"
          onChange={handleChange}
          value={panelSize}
        >
          {options.map(({ value, text }) => (
            <option value={text} key={value}>
              {text}
            </option>
          ))}
        </Select>
      </div>
      <div className="w-full text-center">
        <Box
          display="flex"
          justifyContent="space-between"
          px="2"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Box>
            <Button
              className={isLoading && 'disabled:cursor-wait'}
              onClick={mintPanel}
              outlined
              disabled={
                isLoading || panelSize.length === 0 || isPrivatePanel(panelSize)
              }
            >
              Mint empty Panel
            </Button>
            <Text fontSize="sm">
              {panelSize.length > 0 &&
                panelsInfo[panelSize].price !== 0 &&
                `ETH ${panelsInfo[panelSize].price}`}
              {panelSize.length > 0 &&
                panelsInfo[panelSize].price === 0 &&
                `This size can't be minted`}
            </Text>
          </Box>
          <Box mt={{ base: 5, md: 0 }}>
            <Button
              disabled={isLoading || !canBuild || panelSize.length === 0}
              onClick={onOpen}
            >
              Mint Panel from Blocks...
            </Button>
            {!canBuild && <Text fontSize="sm">Wait 10 Ethereum Blocks</Text>}
            {canBuild && (
              <Text fontSize="sm">{panelSize.length > 0 && 'Free'}</Text>
            )}
          </Box>
          <Modal onClose={onClose} isOpen={isOpen} size="full">
            <PanelContainerModal
              title="Build Panel"
              shouldRenderPanel
              renderContainer={
                <FillPanelModal size={panelSize} closePanel={onClose} />
              }
            />
          </Modal>
        </Box>
        {mintStatus === 'loading' && <LoadingApproval />}
        {mintStatus === 'loading transaction' && (
          <LoadingTransaction transactionHash={lastTransactionHash} />
        )}
        {mintStatus === 'success' && <LoadingSuccessful />}
        {mintStatus === 'error' && <LoadingError>{errorMessage}</LoadingError>}
      </div>
    </div>
  )
}

export default MintPanelModal

import { useContext } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box } from '@chakra-ui/layout'
import PanelContext from 'context/PanelContext'
import Button from 'components/atoms/Button'
import BlocksView from '../BlocksView'
import PanelsView from '../PanelsView'

function MyWallet() {
  const { blocks, panelList } = useContext(PanelContext)
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const {
    isOpen: isOpenPanelPreview,
    onOpen: onOpenPanelPreview,
    onClose: onClosePreview,
  } = useDisclosure(false)

  return (
    <Box mt="10" mb="-10" textAlign="center">
      {blocks.length > 0 && (
        <Button small onClick={onOpen}>
          My blocks
        </Button>
      )}
      {panelList.length > 0 && (
        <Box ml={blocks.length > 0 ? 3 : 0} display="inline-block">
          <Button small onClick={onOpenPanelPreview}>
            My panels
          </Button>
        </Box>
      )}
      {isOpen && <BlocksView onClose={onClose} isOpen={isOpen} />}
      {isOpenPanelPreview && (
        <PanelsView onClose={onClosePreview} isOpen={isOpenPanelPreview} />
      )}
    </Box>
  )
}

export default MyWallet

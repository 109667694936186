import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Route, useHistory } from 'react-router'
import { formatUnitToNumber } from 'utils/formatUnit'
import EthersContext from 'context/EthersContext'
import { Box, Heading, Text } from '@chakra-ui/layout'
import { PanelContextProvider } from 'context/PanelContext'
import Card from 'components/molecules/Card'
import FillEmptyPanel from 'components/organisms/FillEmptyPanel'
import CardButton from 'components/molecules/CardButton'
import Header from 'components/organisms/Header'
import Footer from 'components/organisms/Footer'
import MintBlock from 'components/organisms/MintBlock'
import NumberFormatted from 'components/atoms/NumberFormatted'
import MintPanel from 'components/organisms/MintPanel'
import MyWallet from 'components/organisms/MyWallet'
import GlobalContext from 'context/GlobalContext'
import Button from 'components/atoms/Button'
import { REFRESH_INTERVAL } from 'enums/dotenv'
import Playground from '../Playground'
import BurnBlocks from './BurnBlocks'
import DescontructPanelSection from './DescontructPanelSection'
import blockImage from './images/block.png'
import panelImage from './images/panel.png'
import contextImage from './images/context.png'

const PanelMint = ({ format = '', panels = {}, price = false }) => {
  const panel = panels[format]
  if (!panel) return ''

  const detail = price ? `${panel.price} ETH` : `${panel.minted}/${panel.limit}`

  return (
    <>
      {format} ({detail})
    </>
  )
}

function Home() {
  const history = useHistory()
  const { ethIsEnabled } = useContext(GlobalContext)
  const {
    blocksMints,
    panelsInfo,
    tilesMinterContract,
    panelsCoreContract,
    provider,
    userAddress,
  } = useContext(EthersContext)
  const [mintIsPaused, setMintIsPaused] = useState(true)
  const [panelsMintedTotal, setPanelsMintedTotal] = useState(0)
  const [panelsMintLimit, setPanelsMintLimit] = useState(0)
  const [panelsMintStarted, setPanelsMintStarted] = useState(false)
  const [panelsBuildStarted, setPanelsBuildStarted] = useState(false)
  const walletIsConnected = useMemo(
    () => ethIsEnabled && userAddress !== '',
    [ethIsEnabled, userAddress]
  )

  const fetchMintIsPaused = useCallback(async () => {
    try {
      const isPaused = await tilesMinterContract?.paused()
      if (isPaused !== mintIsPaused) setMintIsPaused(isPaused)
    } catch (error) {
      console.log('Error on fetchMintIsPaused', error)
    }
  }, [mintIsPaused, tilesMinterContract])

  const fetchPanelMintStarted = useCallback(async () => {
    try {
      const isStarted = await tilesMinterContract?.panelMintStarted()

      setPanelsMintStarted(isStarted)
    } catch (error) {
      console.log('Error on fetchPanelMintStarted', error)
    }
  }, [tilesMinterContract])

  const fetchPanelBuildStarted = useCallback(async () => {
    if (!panelsMintStarted) {
      setPanelsBuildStarted(false)
      return
    }
    try {
      const blockNumber = await provider?.getBlockNumber()
      let mintStartBlock = await tilesMinterContract?.panelMintStartBlock()
      mintStartBlock = parseInt(formatUnitToNumber(mintStartBlock))

      const isStarted = blockNumber >= mintStartBlock + 10

      setPanelsBuildStarted(isStarted)
    } catch (error) {
      console.log('Error on fetchPanelBuildStarted', error)
    }
  }, [panelsMintStarted, provider, tilesMinterContract])

  const setTotalPanelMints = useCallback(async () => {
    try {
      const totalGlobalMints = await panelsCoreContract?.totalGlobalMints()
      if (totalGlobalMints && totalGlobalMints !== panelsMintedTotal)
        setPanelsMintedTotal(parseInt(formatUnitToNumber(totalGlobalMints), 10))
    } catch (error) {
      console.log('Error on setTotalPanelMints', error)
    }
  }, [panelsCoreContract, panelsMintedTotal])

  const fetchPanelsMintLimit = useCallback(() => {
    const panelsSumLimit =
      Object.keys(panelsInfo).reduce(
        (acc, key) => acc + parseInt(panelsInfo[key].limit, 10),
        0
      ) || 0
    if (panelsSumLimit !== panelsMintLimit) setPanelsMintLimit(panelsSumLimit)
  }, [panelsInfo, panelsMintLimit])

  const fetchAllAPIs = useCallback(() => {
    if (!ethIsEnabled) return
    fetchMintIsPaused()
    fetchPanelMintStarted()
    setTotalPanelMints()
    fetchPanelsMintLimit()
    fetchPanelBuildStarted()
  }, [
    ethIsEnabled,
    fetchMintIsPaused,
    fetchPanelMintStarted,
    fetchPanelsMintLimit,
    setTotalPanelMints,
    fetchPanelBuildStarted,
  ])

  useEffect(() => {
    fetchAllAPIs()
    const interval = setInterval(() => {
      fetchAllAPIs()
    }, REFRESH_INTERVAL)
    return () => clearInterval(interval)
  }, [ethIsEnabled, fetchAllAPIs])

  useEffect(() => {
    if (!ethIsEnabled || !panelsMintStarted) return
    fetchPanelBuildStarted()
  }, [ethIsEnabled, fetchPanelBuildStarted, panelsMintStarted])

  const blocksMintIsOpened =
    parseInt(blocksMints.total, 10) < parseInt(blocksMints.limit, 10)
  const panelsMintIsOpened =
    panelsMintStarted && panelsMintedTotal < panelsMintLimit

  return (
    <>
      <Header
        mintIsPaused={mintIsPaused}
        panelsMintStarted={panelsMintStarted}
      />
      {!walletIsConnected && (
        <Text
          mb="10"
          mx="auto"
          p="2"
          textAlign="center"
          textColor="white"
          bgColor="blackAlpha.900"
          position="fixed"
          width="full"
          zIndex="2"
        >
          You need a wallet connected to see this website properly
        </Text>
      )}
      <Box as="main" px={{ base: 3, md: 0 }}>
        <PanelContextProvider>
          <Route path="/playground" component={Playground} />
          <Box my={{ base: 20, md: 48 }} mx="auto" maxW="4xl" minH="screen">
            <Text mb="10" textAlign="center" fontSize="lg" fontWeight="300">
              A generative art experiment
            </Text>
            <Heading
              as="h1"
              textAlign="center"
              fontSize={{ base: '2.45em', md: '3.45em' }}
            >
              The Panels is an experiment about emergence arising from
              collective intelligence.
            </Heading>
            <Text mt="8" mx="auto" px="18" textAlign="center" fontSize="lg">
              It's composed of several abstract elements that can be arranged
              into more complex and meaningful experiences. This project also
              borrows some aspects and elements of game theory and biology.
            </Text>
            <Text mt="8" mx="auto" px="18" fontSize="lg" textAlign="center">
              This project is a collab between Arihz and Flamingo DAO, with the
              participation of several other communities: FingerprintsDAO,
              SquiggleDAO, NounsDAO, BeetsDAO, EmergenceDAO, BlockArt, Gen.art,
              PleasrDAO, Non-Fungible Heroes, SharkDAO, Nah Fungible Bones
            </Text>
            {ethIsEnabled && <MyWallet />}
          </Box>
          <Box
            as="section"
            aria-label="quotation"
            my={{ base: 40, md: 20 }}
            mx="auto"
            w="95%"
            maxW="screen"
            textAlign="left"
            fontStyle="italic"
            display={{ md: 'flex' }}
            justifyContent="space-between"
          >
            <article className="flex-1">
              <Heading size="lg" fontWeight="400">
                "In philosophy, systems theory, science, and art, emergence
                occurs when an entity is observed to have properties its parts
                do not have on their own, properties or behaviors which emerge
                only when the parts interact in a wider whole."
              </Heading>
            </article>
            <article className="flex-1 mt-10 md:mt-0">
              <Heading size="lg" fontWeight="400">
                "Art is only a building block, from which we set up meaning"
              </Heading>
            </article>
          </Box>

          <Box as="section" my={{ base: 0, md: 20 }} py={{ base: 0, md: 20 }}>
            <div className="mx-auto md:w-9/12">
              <Heading as="h1" textAlign="center">
                The Panels have two main concepts
              </Heading>
              <div className="my-16 md:flex">
                <Card
                  title="Blocks."
                  image={blockImage}
                  alt="A rando block example"
                  figcaption="A random Block"
                  className="flex-1 mb-14 md:mb-0"
                >
                  <b>Blocks</b> are single independent generative art pieces,
                  created on mint by a unique hash. They come in different
                  shapes and colors, and some of them might resonate with
                  others. They are inspired by aspects of the light, the sound
                  and the touch. There are also references to other generative
                  art projects.
                </Card>
                <Card
                  title="Panels."
                  image={panelImage}
                  alt="A 3x3 Panel filled by randomic blocks"
                  figcaption="A 3x3 Panel generated from random Blocks"
                  className="flex-1"
                >
                  <b>Panels are</b> the final artworks, formed by several
                  Blocks. Panels can be 2x2, 3x3, 4x4, 5x5 or 6x6 blocks. Each
                  Panel is <b>created through the burning of a set of Blocks</b>
                  . The new Panel is the combination of the burnt set. It's up
                  to the creator to decide how those Blocks will be aligned and
                  displayed in the final work. Atention: the border of the Panel
                  is generative and created on mint.
                </Card>
              </div>
            </div>
          </Box>

          <section className="my-20">
            <div className="mx-auto md:w-9/12">
              <Heading as="h1" size="xl" textAlign="center">
                Mints.
              </Heading>
              <Text mt="8" mx="auto" maxW="lg" textAlign="center">
                The minting will take place in two phases. In the first one,
                Blocks are minted. In the second one, Panels are minted. There
                is a mechanism that will allow the Panels minting to happen in a
                slow pace during the course of 12 months.
              </Text>
              <Box
                as="article"
                className="justify-between my-16 md:flex"
                textAlign={{ base: 'center', md: 'left' }}
              >
                <Heading
                  as="h3"
                  size="lg"
                  flex="1"
                  mb={{ base: 4, md: 0 }}
                  mx={{ base: 'auto', md: '5' }}
                >
                  Blocks.
                </Heading>
                <Text flex="1">
                  <b>Block</b> mints will 25th Oct 2021 around 12:00pm ET. They
                  will have a flat {blocksMints.price} ETH cost
                  {walletIsConnected && (
                    <>
                      , limited to <NumberFormatted value={blocksMints.limit} />{' '}
                      pieces
                    </>
                  )}
                  . Total minted blocks:{' '}
                  {walletIsConnected &&
                    (blocksMintIsOpened ? (
                      `${blocksMints.total}/${blocksMints.limit}`
                    ) : (
                      <b>
                        <i>all blocks have been minted</i>
                      </b>
                    ))}
                  {!walletIsConnected && (
                    <Text as="span" fontStyle={'italic'}>
                      You need to connect your wallet to see this info
                    </Text>
                  )}
                </Text>
              </Box>

              <Box
                as="article"
                className="justify-between my-16 md:flex"
                textAlign={{ base: 'center', md: 'left' }}
              >
                <Heading
                  as="h3"
                  size="lg"
                  flex="1"
                  mb={{ base: 4, md: 0 }}
                  mx={{ base: 'auto', md: '5' }}
                >
                  Panels.
                </Heading>
                <div className="flex-1">
                  <Text>
                    <b>Panel</b> mints start after all Blocks are minted. Panels
                    are minted from Blocks burns. Burning 4 blocks will create a
                    2x2 Panel, 9 blocks will create a 3x3 Panel and so on. The
                    mint from burns are <b>free</b>.
                  </Text>
                  <Text my="6">
                    It's also possible to mint <b>empty Panels</b>, that can
                    filled with Blocks later, in the next 12 months. However,
                    empty Panels do have a cost. It's not possible to mint
                    partial Panels.{' '}
                    {walletIsConnected && (
                      <>
                        Costs for empty Panels are:{' '}
                        <PanelMint format="2x2" panels={panelsInfo} price />,{' '}
                        <PanelMint format="3x3" panels={panelsInfo} price />,{' '}
                        <PanelMint format="4x4" panels={panelsInfo} price />,{' '}
                        <PanelMint format="5x5" panels={panelsInfo} price />,{' '}
                        <PanelMint format="6x6" panels={panelsInfo} price />
                      </>
                    )}
                  </Text>

                  {walletIsConnected && (
                    <Text>
                      The total mints are:{' '}
                      <PanelMint format="2x2" panels={panelsInfo} />,{' '}
                      <PanelMint format="3x3" panels={panelsInfo} />,{' '}
                      <PanelMint format="4x4" panels={panelsInfo} />,{' '}
                      <PanelMint format="5x5" panels={panelsInfo} />,{' '}
                      <PanelMint format="6x6" panels={panelsInfo} />,{' '}
                      <PanelMint format="3x2" panels={panelsInfo} />,{' '}
                      <PanelMint format="2x1" panels={panelsInfo} />. The limits
                      include both empty and non-empty Panels.
                    </Text>
                  )}
                </div>
              </Box>
              {!mintIsPaused && (
                <Box
                  display="flex"
                  justifyContent="space-around"
                  pt="5"
                  flexDir={{ base: 'column', md: 'row' }}
                >
                  {blocksMintIsOpened && <MintBlock />}
                  {panelsMintIsOpened && (
                    <MintPanel canBuild={panelsBuildStarted} />
                  )}
                  {panelsMintStarted && panelsBuildStarted && (
                    <FillEmptyPanel />
                  )}
                </Box>
              )}
            </div>
          </section>

          <section className="pb-6 py-20">
            <Heading as="h1" size="xl" textAlign="center" mb="16" mt="14">
              Additional mechanisms
            </Heading>
            <div className="flex flex-col md:flex-row">
              <BurnBlocks />
              <DescontructPanelSection />
              <CardButton
                className="flex-1"
                title="Panel metadata controlled by the minter"
              >
                The Panel name and description are defined by the minter. These
                properties can be set upon mint or when filling the Panel with
                blocks. The other attributes depend on the combination of the
                Blocks used, like how many "Greens" or "Explicit connections"
                the Panels has.
              </CardButton>
            </div>
          </section>
        </PanelContextProvider>
        <section className="my-20 px-4">
          <Heading as="h1" size="xl" textAlign="center" pb="10">
            The Playground
          </Heading>
          <div className="mx-auto max-w-lg">
            <Text>
              It's possible to play a little bit with Blocks to test and explore
              some Panel configurations.
            </Text>
            <br />
            <Text>
              In the Playground you can load Blocks IDs and use a builder to
              simulate a Panel building. You can load your own Blocks IDs or
              arbitrary IDs.
            </Text>
            <br />
            <Text>
              You can also share the builder configuration with others, in order
              to facilitate coordination and collaboration.
            </Text>
          </div>
          <Box textAlign="center" mt="10">
            <Button onClick={() => history.push('/playground')}>
              Open the Playground...
            </Button>
          </Box>
        </section>
        <section className="my-20 px-4">
          <Heading as="h1" size="xl" textAlign="center" pb="10">
            Context
          </Heading>
          <div className="mx-auto max-w-lg">
            <Text>
              The main purpose of this project is to decentralize the collection
              creation. Collectively, the community will build and the rarities,
              the available supply, and the final collection aesthetics will
              emerge. It's also an invitation for people to have a more
              immersive experience through the minting process, with the
              artwork, and with the community.
            </Text>
            <br />
            <Text>
              However, this project is definitely a bad investment bet. Don't
              speculate on this. Moreover, slow minting can be bad for price
              discovery. Only join this experiment if you want to be an active
              entity.
            </Text>
            <br />
            <Text>
              There are plenty of potential topics involved in this simple
              experiment: creativity, cooperation, coordination, delegation,
              analysis paralysis, competition, to name a few. Have fun!
            </Text>
          </div>
        </section>
        <section className="my-20 px-4">
          <Heading as="h1" size="xl" textAlign="center" pb="10">
            Tech
          </Heading>
          <div className="mx-auto max-w-lg">
            <Text>
              This project lives in the Ethereum blockchain, as ERC721 smart
              contracts (Blocks, Panels). There is also a Minter contract that
              controls the public interface of the mechanisms.
            </Text>
            <br />
            <Text>
              The Blocks are generative artworks created on mint using a
              pseudorandom hash generator. Their hash strings are stored forever
              on-chain, as well as the p5js script to generate the artwork.
            </Text>
            <br />
            <Text>
              The Panels are composed of Blocks, and also store their
              degradation level, name and description. The script to generate
              the artwork is also stored on-chain.
            </Text>
          </div>
        </section>
        <div
          className="min-w-screen my-20 h-96 bg-cover bg-center bg-no-repeat md:h-screen md:bg-fixed"
          style={{
            backgroundImage: `url(${contextImage})`,
            maxHeight: '1000px',
          }}
        />
        <section className="my-20 px-4">
          <Heading as="h1" size="xl" textAlign="center" pb="10">
            FAQ
          </Heading>
          <article className="mx-auto max-w-lg">
            <Text>
              <b>Is there a limited supply of empty and filled Panels?</b>
              <br />
              Yes, there is a limited supply of Panels (see the "Mints"
              section). However, there's no restrictions on the proportion of
              the empty and filled Panels. So it's possible that all Panels are
              minted empty or the other way around. First come, first served.
            </Text>
            <br />
            <Text>
              <b>Is the final Panel set immutable after the 12 month period?</b>
              <br />
              Yes, after the 12 month period, the Panel mints, fillings and
              deconstructions are disabled. Empty Panels will remain empty
              forever.
            </Text>
            <br />
            <Text>
              <b>What does happen to the Blocks after the 12 month period?</b>
              <br />
              The Blocks are not affected by the 12 month period.
            </Text>
            <br />
            <Text>
              <b>Is the border of the Panel generated randomly?</b>
              <br />
              Yes, the border of the Panel is generative, and it's color and
              strips are generated on mint. You cannot change it, even if you
              don't like it.
            </Text>
            <br />
            <Text>
              <b>What about the 2x1 and 3x2 Panels?</b>
              <br />
              There are also 2x1 and 3x2 Panels. Those panels are reserved for
              Arihz (and contributors), and Emergence holders (Frams or Puls),
              respectively. There're no empty panels in those sizes.
            </Text>
          </article>
        </section>
        <section className="my-20 px-4">
          <Heading as="h1" size="xl" textAlign="center" pb="10">
            Tips
          </Heading>
          <article className="mx-auto max-w-lg">
            <Text>
              - Everything about this project is an experiment. The trading
              dynamic, the distribution of Blocks and Panels, the early choices
              and edges cases; they are all to be discovered. Some holders may
              end up in uncomfortable positions, and it's also part of the
              experiment.
            </Text>
            <br />
            <Text>
              - Is theoretically possible that we end up in a situation where
              all Panels are minted empty or none are empty. The Panels mints
              are first come, first served, empty or not.
            </Text>
            <br />
            <Text>
              - Even if you buy Blocks, it's possible that you're not able to
              mint Panels. There is a limited number of Panels of each size.
              Some Block holders will not have a Panel to mint. If you end up in
              this case, congrats, you're officially part of the experiment.
              That said, I have plans to give another utility layer to unused
              Blocks in the far future.
            </Text>
            <br />
            <Text>
              - Free panels must be minted from the Blocks. Empty Panels have a
              cost. There're advantages of having empty Panels. One can choose
              which Panel to build after knowing how the other Panels look like
              and their properties. Also you can make sure you have a Panel
              before all Panels are minted.
            </Text>
            <br />
            <Text>
              - When Panel mints start, in the first 10 Blocks (around 2 min),
              only empty Panels are allowed.
            </Text>
          </article>
        </section>
      </Box>
      <Footer />
    </>
  )
}

export default Home

import { useDisclosure } from '@chakra-ui/hooks'
import Button from 'components/atoms/Button'
import PanelContext from 'context/PanelContext'
import { useContext } from 'react'
import FillEmptyPanelModal from '../FillEmptyPanelModal'

function FillEmptyPanel() {
  const { emptyPanels } = useContext(PanelContext)
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  if (emptyPanels.length <= 0) return <></>

  return (
    <>
      <Button
        className="px-8 py-5 text-white text-sm bg-black"
        onClick={onOpen}
      >
        Fill Empty Panel...
      </Button>
      <FillEmptyPanelModal onClose={onClose} isOpen={isOpen} />
      {/* <Heading size="md">Fill Empty Panel</Heading> */}
    </>
  )
}

export default FillEmptyPanel

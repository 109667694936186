import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { useDisclosure } from '@chakra-ui/hooks'
import Button from 'components/atoms/Button'
import { Box, Heading } from '@chakra-ui/layout'
import MintPanelModal from '../MintPanelModal'

function MintPanel({ canBuild = false }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  return (
    <Box my={{ base: 2, md: 0 }}>
      <Button
        className="px-8 py-5 w-full text-white text-sm bg-black"
        onClick={onOpen}
      >
        Mint Panel...
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="lg">
        <ModalOverlay />
        <ModalContent borderRadius="none">
          <ModalHeader>
            <Heading size="md">Mint Panel</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MintPanelModal canBuild={canBuild} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default MintPanel

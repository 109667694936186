import { useCallback, useEffect, useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Image,
  useDisclosure,
  Box,
  Button,
  Skeleton,
} from '@chakra-ui/react'
import { useRouteMatch } from 'react-router'
import { Fade } from '@chakra-ui/transition'
import { GridItem, Text } from '@chakra-ui/layout'
import { CloseIcon } from '@chakra-ui/icons'

const BlockThumbnail = ({
  setImageOnHover,
  setFilledBy,
  onFilled,
  onClose,
  block,
  onError = () => {},
}) => {
  const [image, setImage] = useState(null)
  const [hasError, setHasError] = useState(false)

  const loadImage = useCallback(() => {
    const windowImage = new window.Image()
    windowImage.src = block.thumbnail
    windowImage.onload = () => {
      setImage(block.thumbnail)
    }
    windowImage.onerror = () => {
      setHasError(true)
      onError(block.id)
    }
  }, [block.id, block.thumbnail, onError])

  useEffect(() => {
    loadImage()
  }, [loadImage])

  if (hasError) return <></>

  return (
    <Skeleton
      isLoaded={image}
      boxSize={{ base: 50, md: 100 }}
      key={block.id}
      display="inline-block"
      m="1"
    >
      <Image
        boxSize="full"
        src={image}
        alt="Loading image"
        _hover={{ cursor: 'pointer' }}
        onMouseEnter={() => {
          setImageOnHover(block)
        }}
        onMouseLeave={() => {
          setImageOnHover(null)
        }}
        onClick={() => {
          setFilledBy(block)
          setImageOnHover(null)
          onFilled(block)
          onClose()
        }}
      />
    </Skeleton>
  )
}

const BlockPanel = ({
  onFilled = () => {},
  numberOfBlocks,
  blocks,
  getImageUrl,
  isDisabled = false,
  block = null,
}) => {
  const matchPlayground = useRouteMatch('/playground')
  const [filledBy, setFilledBy] = useState(block)
  const [imageOnHover, setImageOnHover] = useState(null)
  const [errorOnLoading, setErrorOnLoading] = useState([])
  const { onOpen, onClose, isOpen } = useDisclosure()

  const onError = useCallback((id) => {
    setErrorOnLoading((state) => (state.includes(id) ? state : [...state, id]))
  }, [])

  useEffect(() => {
    setFilledBy(null)
  }, [numberOfBlocks])

  useEffect(() => {
    if (!isOpen) setErrorOnLoading([])
  }, [isOpen])

  useEffect(() => {
    if (block) {
      setFilledBy(block)
    } else {
      setFilledBy(null)
    }
  }, [block])

  const hasBlocksToDisplay =
    blocks.length > 0 && errorOnLoading.length !== blocks.length

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={isDisabled ? () => {} : onOpen}
      onClose={onClose}
      placement="auto-start"
      closeOnBlur={true}
      gutter={20}
      arrowShadowColor="black"
    >
      <PopoverTrigger>
        <GridItem
          padding={filledBy || imageOnHover ? '0' : '1%'}
          bgColor="rgb(40,40,40)"
          _hover={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        >
          <Box
            bgColor="gray.50"
            boxSize="full"
            bgImage={`url(${(imageOnHover || filledBy)?.thumbnail ?? ''})`}
            bgPos="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            position="relative"
            role="group"
          >
            {!imageOnHover && filledBy && (
              <>
                <Fade in={filledBy}>
                  <Image
                    boxSize="full"
                    src={getImageUrl(filledBy.id, numberOfBlocks)}
                  />
                </Fade>
                {!isDisabled && (
                  <Button
                    position="absolute"
                    right="3%"
                    top="3%"
                    opacity="0.5"
                    zIndex="-1"
                    _hover={{ opacity: 1 }}
                    _groupHover={{ zIndex: 10 }}
                    onClick={(e) => {
                      e.preventDefault()
                      setFilledBy(null)
                      setImageOnHover(null)
                      onFilled(null)
                    }}
                    width="20%"
                    height="22%"
                    maxH="40px"
                    maxW="40px"
                    minWidth="unset"
                    minHeight="unset"
                  >
                    <CloseIcon />
                  </Button>
                )}
              </>
            )}
          </Box>
        </GridItem>
      </PopoverTrigger>
      <PopoverContent
        width={{ base: 200, md: 500 }}
        borderColor="black"
        textAlign="center"
      >
        <PopoverHeader>
          {hasBlocksToDisplay
            ? 'Choose your block'
            : 'No more blocks to select'}
        </PopoverHeader>
        {hasBlocksToDisplay && (
          <>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody maxHeight={{ base: 200, md: 400 }} overflow="auto">
              {blocks.map((block) => (
                <BlockThumbnail
                  key={block.id}
                  setImageOnHover={setImageOnHover}
                  setFilledBy={setFilledBy}
                  onFilled={onFilled}
                  onClose={onClose}
                  block={block}
                  onError={onError}
                />
              ))}
            </PopoverBody>
          </>
        )}
        {!hasBlocksToDisplay && matchPlayground && (
          <>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Text>Please load blocks on the input</Text>
            </PopoverBody>
          </>
        )}
      </PopoverContent>
    </Popover>
  )
}

export default BlockPanel

import env from 'env-var'

const envPrefix = 'REACT_APP_'
const buildEnvName = (value) => `${envPrefix}${value}`

export const GAS_LIMIT = env
  .get(buildEnvName('GAS_LIMIT'))
  .default(3000000)
  .asIntPositive()

export const BLOCKS_CORE_ADDRESS = env
  .get(buildEnvName('BLOCKS_CORE_ADDRESS'))
  .required()
  .asString()

export const PANELS_CORE_ADDRESS = env
  .get(buildEnvName('PANELS_CORE_ADDRESS'))
  .required()
  .asString()

export const TILES_MINTER_ADDRESS = env
  .get(buildEnvName('TILES_MINTER_ADDRESS'))
  .required()
  .asString()

export const PROVIDER_URL = env
  .get(buildEnvName('PROVIDER_URL'))
  .required()
  .asUrlString()

export const S3_URL = env.get(buildEnvName('S3_URL')).required().asUrlString()

export const PANELS_URL = `${S3_URL}panels`
export const BLOCKS_URL = `${S3_URL}blocks`
export const ASSETS_URL = `${S3_URL}assets`

export const REFRESH_INTERVAL = env
  .get(buildEnvName('REFRESH_INTERVAL'))
  .default(5000)
  .asIntPositive()
